import { Alert, Typography } from "@mui/material";
import { useUACProject } from "dataHooks";

export const UACSystemLabel = ({ uacProjectId }: { uacProjectId: string }) => {
  const {
    data: uacProject,
    error,
    isLoading,
  } = useUACProject({
    uac_project_id: uacProjectId,
    include_uac_account: true,
  });

  if (isLoading) {
    return null;
  }

  if (error || !uacProject) {
    return <Alert severity="error">Error loading integration details.</Alert>;
  }

  return (
    <Typography variant="subtitle1">
      {uacProject.uac_account?.source_system}: {uacProject.name}
    </Typography>
  );
};
