import { z } from "zod";
import { Check, CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Form } from "@trunk-tools/ui";
import { showToast } from "@/components/Toasts/ToastService";
import { useCreateProject, useUserBusinesses } from "dataHooks";

const projectFields = [
  ["Project Name", "project_name"],
  ["Business", "business_id"],
  ["Timezone", "timezone"],
];

interface CreateNewProjectModalProps {
  onClose: () => void;
  onSuccess: () => void;
  manageableBusinessIds: string[];
  hasGlobalPermission: boolean;
}

const timezones = [
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
];

export const CreateNewProjectModal = ({
  onClose,
  manageableBusinessIds,
  hasGlobalPermission,
  onSuccess,
}: CreateNewProjectModalProps) => {
  const onCreateNewProject = ({
    business_id,
    project_name,
    timezone,
  }: {
    project_name: string;
    business_id: string;
    timezone: string;
  }) => {
    createProject({
      name: project_name,
      business_id,
      timezone,
      project_config: {
        process_images: true,
      },
    });
  };

  const { businesses } = useUserBusinesses();

  const manageableBusinesses = hasGlobalPermission
    ? businesses
    : businesses.filter(({ id }) => manageableBusinessIds.includes(id));
  const { trigger: createProject, isMutating } = useCreateProject({
    onSuccess: async () => {
      onSuccess();
      onClose();
      showToast({
        type: "success",
        message: "Project Created!",
      });
    },
    onError: () => {
      showToast({
        type: "error",
        message: "Failed to create Project",
      });
    },
  });

  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <DialogTitle>Create New Project</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          padding: 0,
        }}
      >
        <CloseRounded className="w-10 h-10" />
      </IconButton>
      <DialogContent>
        <Form.Provider
          initialValues={{
            project_name: "",
            business_id: businesses[0].id,
            timezone: "US/Eastern",
          }}
          validators={{
            project_name: Form.validators.nonEmptyString(),
            business_id: z.string().min(1, "Please select a role"),
            timezone: Form.validators.nonEmptyString(),
          }}
          onSubmit={async (values) => {
            await onCreateNewProject(values);
          }}
          submitButtonText={false}
          children={(form) => (
            <>
              {projectFields.map(([displayName, propertyName]) => {
                let inner: JSX.Element = (
                  <Form.Fields.Text
                    rounded
                    name={propertyName}
                    hasError={
                      !!form.errors[propertyName] && form.touched[propertyName]
                    }
                    size="lg"
                  />
                );
                if (propertyName === "business_id") {
                  inner = (
                    <FormControl fullWidth>
                      <Select
                        value={form.values[propertyName]}
                        onChange={(e) =>
                          form.setFieldValue(propertyName, e.target.value)
                        }
                        label="Select Role"
                      >
                        {manageableBusinesses.map(({ name, id }) => (
                          <MenuItem value={id}>{name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                } else if (propertyName === "timezone") {
                  inner = (
                    <FormControl fullWidth>
                      <Select
                        value={form.values["timezone"]}
                        onChange={(e) =>
                          form.setFieldValue("timezone", e.target.value)
                        }
                        label="Select Project Timezone"
                      >
                        {timezones.map((tz) => (
                          <MenuItem value={tz}>{tz}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }

                return (
                  <div key={`form-field-${propertyName}`}>
                    <Typography variant="body2" className="mb-2 mt-8 uppercase">
                      {displayName}
                    </Typography>
                    {inner}
                    {form.errors[propertyName] &&
                      form.touched[propertyName] && (
                        <div className="relative">
                          <Typography
                            variant="body2"
                            className="top-1 left-2 text-red-500 absolute"
                          >
                            {form.errors[propertyName]}
                          </Typography>
                        </div>
                      )}
                  </div>
                );
              })}

              <div className="flex justify-end my-6">
                <div>
                  <LoadingButton
                    disabled={isMutating || !form.isValid}
                    loading={form.isSubmitting}
                    startIcon={<Check />}
                    color="success"
                    type="submit"
                  >
                    Save Changes
                  </LoadingButton>
                </div>
              </div>
            </>
          )}
        ></Form.Provider>
      </DialogContent>
    </Dialog>
  );
};
