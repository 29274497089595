import useSWR from "swr";
import { api } from "./helpers/APIClient";

export const useDocument = ({ documentId }: { documentId: string }) => {
  const fetch = async () => {
    const data = await api.getDocument({
      document_id: documentId,
    });
    return data;
  };

  const { data: document, mutate } = useSWR(["document", documentId], fetch, {
    suspense: true,
    /**
     * Don't cache the document because there's a temporary signed URL
     * that expires after 3 minutes, and thus could expire between the
     * time that it's initially fetched on one page and then fetched
     * from the cache on another page.
     */
    dedupingInterval: 2000,
  });

  const ext = document.name.split(".").pop();

  return { document, refreshDocument: mutate, ext };
};

export const useDocuments = ({ documentIds }: { documentIds: string[] }) => {
  const fetch = async () => {
    const data = await Promise.all(
      documentIds.map((documentId) =>
        api.getDocument({ document_id: documentId }),
      ),
    );

    return data;
  };

  const { data: documents, mutate } = useSWR(
    ["documents", ...documentIds],
    fetch,
    {
      suspense: true,
    },
  );

  return { documents, refreshDocument: mutate };
};

export const useDocumentAttachments = ({
  documentId,
}: {
  documentId: string;
}) => {
  const fetch = async () => {
    const data = await api.getDocumentAttachments({
      document_id: documentId,
    });
    return data;
  };

  const {
    data: { documentIds },
    mutate,
  } = useSWR(["documentAttachments", documentId], fetch, { suspense: true });

  return { documentIds, refreshDocumentAttachments: mutate };
};

export const useQuestionDocumentDownload = ({
  questionId,
  documentId,
}: {
  questionId: string;
  documentId: string;
}) => {
  const fetch = async () => {
    return null;
  };

  const { data, mutate } = useSWR(
    ["questionDocumentDownload", questionId, documentId],
    fetch,
    {
      suspense: true,
    },
  );

  return { data, mutate };
};
