import useSWRMutation from "swr/mutation";
import { api } from "./helpers/APIClient";
import { UserUpdateProfileBodyType } from "@trunk-tools/txt-shared";

export const useUpdateProfile = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const updateProfileFetch = async (
    key: unknown,
    { arg }: { arg: UserUpdateProfileBodyType },
  ) => {
    const result = await api.userUpdateProfile(arg);
    if (!result) {
      throw new Error("Failed to update profile");
    }
    return result;
  };

  return useSWRMutation(["updateProfile"], updateProfileFetch, {
    onSuccess,
    onError,
  });
};
