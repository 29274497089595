import { Route } from "react-router-dom";
import { AskPage } from "@/pages/Ask.page";
import { LogoutPage } from "@/pages/Logout.page";
import { LoginPage } from "@/pages/Login.page";
import { ConsentPage } from "@/pages/Consent.page";
import { IntegrationsPage } from "@/pages/Integrations/Integrations.page";
import { NewIntegrationPage } from "@/pages/Integrations/NewIntegration.page";
import { IntegrationPage } from "@/pages/Integrations/Integration.page";
import { ManageAgentsPage } from "@/pages/ManageAgents.page";
import { NewAgentPage } from "@/pages/NewAgent.page";
import { DocumentPage } from "@/pages/Document.page";
import { QuestionDocumentPage } from "@/pages/QuestionDocument.page";
import { QuestionPage } from "@/pages/Question.page";
import { NoProjectsPage } from "@/pages/NoProjects.page";
import { ManagePage } from "@/pages/Manage.page";
import { ShareConversationPage } from "@/pages/SharedConversation.page";
import { ProjectRouteWrapper, SelfServeProtectedRoute } from "./routerWrappers";

// DEVS: define your routes here

// MUST BE LOGGED IN
// ROUTES THAT ARE NESTED UNDER A PROJECT ID
const projectProtectedRoutes = [
  <Route path="agents" element={<ManageAgentsPage />} key="manage-agents" />,
  <Route path="agents/new" element={<NewAgentPage />} key="agents-new" />,
  <Route path="agents/:agentId" element={<NewAgentPage />} key="agents-edit" />,
  <Route path="questions" element={<AskPage />} key="home" />,
  <Route
    path="conversations"
    element={<AskPage />}
    key="conversations-empty"
  />,
  <Route
    path="conversations/:conversationId"
    element={<AskPage />}
    key="conversations-id"
  />,
  <Route
    path="conversations/new"
    element={<AskPage />}
    key="conversations-new"
  />,
  <Route
    path="integrations/new"
    element={<NewIntegrationPage />}
    key="new-integration"
  />,
  <Route
    path="integrations"
    element={<IntegrationsPage />}
    key="integrations"
  />,
  <Route
    path="integrations/:uacProjectId"
    element={<IntegrationPage />}
    key="integrations"
  />,
  <Route
    path="questions/:questionId"
    element={<QuestionPage />}
    key="question"
  />,
  <Route
    path="questions/:questionId/document/:documentId"
    element={<QuestionDocumentPage />}
    key="question-document"
  />,
  <Route
    path="documents/:documentId"
    element={<DocumentPage />}
    key="documentPage"
  />,
];

export const selfServeRoutes = [
  <Route path="manage" element={<ManagePage />} key="manage-home">
    <Route path=":tab" element={<ManagePage />} key="manage-tab" />
    <Route
      path="projects/:projectId/users"
      element={<ManagePage />}
      key="manage-tab"
    />
  </Route>,
];

export const protectedRoutes = [
  <Route
    path="/projects/:projectId"
    element={<ProjectRouteWrapper />}
    key="projectProtectedRoutes"
  >
    {projectProtectedRoutes}
  </Route>,
  <Route path="consent" element={<ConsentPage />} key="consent" />,
  <Route path="no-projects" element={<NoProjectsPage />} key="no-projects" />,
  <Route element={<SelfServeProtectedRoute />} key="selfServe">
    {selfServeRoutes}
  </Route>,
];

// MUST NOT BE LOGGED IN
export const isNotLoggedInRoutes = [
  <Route path="/login" element={<LoginPage />} key="login" />,
];

// DON'T CARE IF LOGGED IN OR NOT
export const openRoutes = [
  <Route path="/logout" element={<LogoutPage />} key="logout" />,
  <Route
    path="/open/shared-conversation"
    element={<ShareConversationPage />}
    key="shared-conversation"
  />,
];
