import {
  useIsLoggedIn,
  useMaybeUserBusinesses,
  useUserBelongsToProject,
} from "dataHooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LoggedInLayout as LoggedIn } from "@/layouts/LoggedIn.layout.NEW";
import { OpenLayout as Open } from "@/layouts/Open.layout";
import { SuspenseErrorBoundary } from "@/layouts/SuspenseErrorBoundary.layout";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { useAuth } from "@frontegg/react";
import { api } from "@/dataHooks/helpers/APIClient";
import { useFeatureFlag } from "@/dataHooks/featureFlag.dataHook";

const NonFEProtectedRoute = () => {
  const { pathname } = useLocation();
  const { isLoggedIn } = useIsLoggedIn();
  const { businesses } = useMaybeUserBusinesses();

  if (!isLoggedIn) {
    // if a user is not logged in but the path matches one of our protected
    // pages create redirect for that page to be used in the login component.
    return (
      <Navigate to={`/login?redirect=${window.location.pathname}`} replace />
    );
  }

  if (isLoggedIn && pathname === "/") {
    return (
      <Navigate to={`/projects/${businesses[0].projects[0].id}`} replace />
    );
  }

  return <Outlet />;
};

const FEProtectedRoute = () => {
  const { pathname } = useLocation();
  const { user, isAuthenticated } = useAuth();
  const { businesses } = useMaybeUserBusinesses();

  if (user) {
    api.setJwtToken(user.accessToken);
  }

  if (!isAuthenticated) {
    // if a user is not logged in but the path matches one of our protected
    // pages create redirect for that page to be used in the login component.
    return (
      <Navigate
        to={`/account/login?redirect=${window.location.pathname}`}
        replace
      />
    );
  }

  if (isAuthenticated && pathname === "/") {
    return (
      <Navigate to={`/projects/${businesses[0].projects[0].id}`} replace />
    );
  }

  return <Outlet />;
};

export const ProtectedRoute = () => (
  <FeatureFlagGate
    enabled={() => <FEProtectedRoute />}
    disabled={() => <NonFEProtectedRoute />}
    flagKey={(FlagKey) => FlagKey.Frontegg}
  />
);

export const RewriteBusinesses = () => {
  const { pathname } = useLocation();

  const newPath = pathname.split("/").splice(3).join("/");

  return <Navigate to={`/${newPath}`} replace />;
};

export const IsNotLoggedIn = () => {
  const { isLoggedIn } = useIsLoggedIn();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export const RouterSuspenseErrorTopLevelBoundary = () => {
  return (
    <SuspenseErrorBoundary size="screen">
      <Outlet />
    </SuspenseErrorBoundary>
  );
};

export const LoggedInLayout = () => {
  return (
    <LoggedIn>
      <Outlet />
    </LoggedIn>
  );
};

export const OpenLayout = () => {
  return (
    <Open>
      <Outlet />
    </Open>
  );
};

export const ProjectRouteWrapper = () => {
  const { userBelongsToProject } = useUserBelongsToProject();

  if (!userBelongsToProject) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export const SelfServeProtectedRoute = () => {
  const { flagEnabled } = useFeatureFlag((ff) => ff.SelfServeProjectManagement);

  if (!flagEnabled) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
