import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { api } from "./helpers/APIClient";
import { useCurrentProject } from "./project.datahook";
import { CorpusFilter } from "@trunk-tools/txt-shared";

export const useCorpusFilter = ({
  corpusFilterId,
}: {
  corpusFilterId?: string | null;
}) => {
  const fetch = async () => {
    if (!corpusFilterId) return null;

    const data = await api.getCorpusFilter({
      corpus_filter_id: corpusFilterId,
    });
    return data;
  };

  return useSWR(
    corpusFilterId ? ["corpusFilter", corpusFilterId] : null,
    fetch,
    {
      suspense: true,
    },
  );
};

export const useUserAndSharedProjectCorpusFilters = ({
  visibility,
}: {
  visibility: CorpusFilter["visibility"] | undefined;
}) => {
  const { currentProject } = useCurrentProject();

  const fetch = async () => {
    if (!currentProject) return [];

    const data = await api.listUserAndSharedProjectCorpusFilters({
      project_id: currentProject.id,
      visibility,
    });
    return data;
  };

  return useSWR(["corpusFilters", currentProject.id], fetch, {
    suspense: true,
  });
};

export const useCreateCorpusFilter = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: CorpusFilter) => void;
  onError?: (error: Error) => void;
} = {}) => {
  const { currentProject } = useCurrentProject();

  const createCorpusFilterFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: {
        name: CorpusFilter["name"];
        filters: CorpusFilter["filters"];
        temporary_custom: CorpusFilter["temporary_custom"];
        visibility: CorpusFilter["visibility"];
      };
    },
  ) => {
    const result = await api.createCorpusFilter({
      project_id: currentProject.id,
      ...arg,
    });

    return result;
  };

  return useSWRMutation(["createCorpusFilter"], createCorpusFilterFetch, {
    onSuccess,
    onError,
  });
};

export const useUpdateCorpusFilter = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: CorpusFilter) => void;
  onError?: (error: Error) => void;
} = {}) => {
  const updateCorpusFilterFetch = async (
    key: unknown,
    { arg }: { arg: CorpusFilter },
  ) => {
    const result = await api.updateCorpusFilter({
      corpus_filter_id: arg.id,
      ...arg,
    });

    return result;
  };

  return useSWRMutation(["updateCorpusFilter"], updateCorpusFilterFetch, {
    onSuccess,
    onError,
  });
};

export const useDeleteCorpusFilter = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
} = {}) => {
  const deleteCorpusFilterFetch = async (
    key: unknown,
    { arg }: { arg: { corpusFilterId: CorpusFilter["id"] } },
  ) => {
    const result = await api.deleteCorpusFilter({
      corpus_filter_id: arg.corpusFilterId,
    });

    return result;
  };

  return useSWRMutation(["deleteCorpusFilter"], deleteCorpusFilterFetch, {
    onSuccess,
    onError,
  });
};
