import { useContext, useMemo, useState } from "react";

// Components
import { FormControlLabel, Checkbox } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataAndDocumentsFolders } from "./DataAndDocumentsFolders.component";

// Contexts
import { CorpusFiltersContext } from "../../../CorpusFilters.context";
import { useDataAndDocumentsContext } from "../DataAndDocuments.context";

// Constants
import { SYNC_CONFIG_MODULE_LABEL_MAP } from "../../../CorpusFilters.constants";
import { DocumentsList } from "../../DocumentsList";
import {
  ExternalDocumentSourceModule,
  Folder,
  SyncConfigModules,
} from "@trunk-tools/txt-shared";

// Data Hooks
import { useSearchProjectDocumentsCount } from "dataHooks";

// Utils
import { cn, colors } from "@trunk-tools/ui";

const UACProjectSyncConfigModuleMap = {
  rfis: ExternalDocumentSourceModule.RFI,
  submittals: ExternalDocumentSourceModule.Submittal,
  specs: ExternalDocumentSourceModule.Specification,
  drawings: ExternalDocumentSourceModule.Drawing,
  meetings: ExternalDocumentSourceModule.MeetingNotes,
  forms: ExternalDocumentSourceModule.Form,
  issues: ExternalDocumentSourceModule.Issue,
  folders: ExternalDocumentSourceModule.File,
  daily_logs: ExternalDocumentSourceModule.DailyLog,
} satisfies Record<string, ExternalDocumentSourceModule>;

export const DataAndDocumentsUACProjectModuleBranch = ({
  uacProjectId,
  syncConfigModuleAttribute,
  watchedFoldersTree,
  flattenedFolderNames,
}: {
  uacProjectId: string;
  syncConfigModuleAttribute: SyncConfigModules;
  watchedFoldersTree: Folder[];
  flattenedFolderNames: string[];
}) => {
  const { debouncedSearchTerm, filters, searchedAt } =
    useDataAndDocumentsContext();
  const [manuallyExpanded, setManuallyExpanded] = useState<boolean>(false);
  const [expansionToggledAt, setExpansionToggledAt] = useState<Date | number>(
    0,
  );

  const { onFilterBlockChange, onFilterBlockDelete } =
    useContext(CorpusFiltersContext);

  const foundFilter = useMemo(() => {
    return filters.find((filter) => {
      if (!filter.document_source_system || !filter.document_source_module) {
        return false;
      }

      return (
        filter.document_source_module ===
          UACProjectSyncConfigModuleMap[syncConfigModuleAttribute] &&
        filter.document_source_system === uacProjectId
      );
    });
  }, [filters]);

  const { data: documentCountRes, isLoading: isDocumentCountLoading } =
    useSearchProjectDocumentsCount({
      uacProjectId,
      syncConfigModule: syncConfigModuleAttribute,
      searchTerm: debouncedSearchTerm,
    });

  const documentCount = documentCountRes?.count || 0;

  let formControlLabel: React.ReactNode =
    SYNC_CONFIG_MODULE_LABEL_MAP[syncConfigModuleAttribute];
  if (isDocumentCountLoading) {
    formControlLabel = (
      <>
        {formControlLabel}
        <span className="animate-pulse"> (..)</span>
      </>
    );
  } else {
    formControlLabel = `${formControlLabel} (${documentCount})`;
  }

  const hasMatchingFolderName = useMemo(() => {
    if (syncConfigModuleAttribute === SyncConfigModules.Folders) {
      const hasMatchingFolderName = flattenedFolderNames.some((folderName) => {
        return folderName.includes(debouncedSearchTerm.toLowerCase());
      });

      return hasMatchingFolderName;
    }

    return false;
  }, [debouncedSearchTerm, flattenedFolderNames, syncConfigModuleAttribute]);

  const hasChildren = documentCount > 0 || hasMatchingFolderName;
  const shouldRender = useMemo(() => {
    if (!debouncedSearchTerm) {
      return true;
    }

    if (hasChildren) {
      return true;
    }

    const matchesModuleName = SYNC_CONFIG_MODULE_LABEL_MAP[
      syncConfigModuleAttribute
    ]
      .toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());

    if (matchesModuleName) {
      return true;
    }

    return false;
  }, [debouncedSearchTerm, hasChildren, syncConfigModuleAttribute]);

  const expanded =
    (debouncedSearchTerm &&
      searchedAt > expansionToggledAt &&
      !isDocumentCountLoading &&
      hasChildren) ||
    manuallyExpanded;

  const isSelected = Boolean(foundFilter);

  return (
    <>
      <div
        style={{ backgroundColor: colors["tt-modal-bg"] }}
        className={cn("flex flex-row items-center gap-2 sticky top-0 z-[1]", {
          hidden: debouncedSearchTerm && !shouldRender,
        })}
      >
        <button
          onClick={() => {
            setExpansionToggledAt(new Date());
            setManuallyExpanded(!expanded);
          }}
          className={cn({ invisible: !hasChildren })}
        >
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </button>
        <FormControlLabel
          control={<Checkbox checked={isSelected} />}
          label={formControlLabel}
          onChange={(_event, checked) => {
            if (checked) {
              onFilterBlockChange({
                operation: "add",
                primaryAttribute: "document_source_module",
                primaryAttributeValue:
                  UACProjectSyncConfigModuleMap[syncConfigModuleAttribute],
                commonAttributes: {
                  document_source_system: uacProjectId,
                },
              });
            } else if (foundFilter) {
              onFilterBlockDelete({ uuidKey: foundFilter._uuidKey });
            }
          }}
        />
      </div>
      {hasChildren && expanded && (
        <div>
          {syncConfigModuleAttribute === SyncConfigModules.Folders ? (
            <div className="ml-6">
              <DataAndDocumentsFolders
                uacProjectId={uacProjectId}
                watchedFoldersTree={watchedFoldersTree}
              />
            </div>
          ) : (
            <div className="ml-13">
              <DocumentsList
                key={`${uacProjectId}-${syncConfigModuleAttribute}-documents`}
                filters={filters}
                searchTerm={debouncedSearchTerm}
                uacProjectId={uacProjectId}
                syncConfigModule={syncConfigModuleAttribute}
                showNoDocumentsMessage
                documentCount={documentCount}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
