export const CustomLoginFooter = () => (
  <div>
    <div>
      <div
        style={{
          textAlign: "center",
          marginTop: "30px",
          marginBottom: "20px",
          fontSize: "12px",
          lineHeight: "16px",
          color: "white",
        }}
      >
        For support, please email{" "}
        <a
          rel="noopener noreferrer"
          style={{ color: "rgb(246, 191, 64)" }}
          href={"mailto:support@trunk.tools"}
        >
          support@trunk.tools
        </a>
      </div>
    </div>

    <div
      style={{
        textAlign: "center",
        marginTop: "15px",
        fontSize: "12px",
        lineHeight: "16px",
        color: "white",
      }}
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "rgb(246, 191, 64)" }}
        href={
          "https://assets.trunk.tools/consent/privacy-policy/2024-09-02/privacy-policy.pdf"
        }
      >
        Privacy Policy {"  "}
      </a>
      |
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "rgb(246, 191, 64)" }}
        href={"https://assets.trunk.tools/consent/tos/2024-09-17/tos.pdf"}
      >
        {"  "} Terms of Service
      </a>{" "}
    </div>
  </div>
);
