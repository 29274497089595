import { ConversationsList } from "@/components/Conversations/ConversationsList.component";
import { UserIconMenu } from "@/components/Conversations/UserIconMenu.component";
import { ProjectPicker } from "@/components/ProjectPicker";
import {
  LoadingChevron,
  LoadingCircle,
  LoadingRectangle,
} from "@trunk-tools/ui";
import { Button } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { Suspense } from "react";
import { useCurrentProject } from "dataHooks";

const SuspenseBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col h-full">
          <LoadingRectangle width="w-full" height="h-14" />
          <div className="w-full mt-12">
            <LoadingRectangle width="w-full" height="h-14" />
          </div>
          <div className="w-full mt-6">
            <LoadingRectangle width="w-full" height="h-14" />
          </div>
          <div className="mt-6">
            <LoadingRectangle width="w-15" height="h-5" />
          </div>
          <div className="mt-6 ml-4">
            <LoadingRectangle width="w-56" height="h-5" />
          </div>
          <div className="mt-6">
            <LoadingRectangle width="w-17" height="h-5" />
          </div>
          {[1, 2, 3, 4, 5].map((n) => (
            <div key={n} className="mt-6 ml-4">
              <LoadingRectangle width="w-40" height="h-5" key={n} />
            </div>
          ))}
          <div className="flex flex-row mt-auto items-center">
            <LoadingCircle />
            <div className="flex flex-col">
              <div className="ml-4">
                <LoadingRectangle width="w-20" height="h-4" />
                <div className="mt-2">
                  <LoadingRectangle width="w-30" height="h-4" />
                </div>
              </div>
            </div>
            <div className="ml-auto">
              <LoadingChevron />
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

type AskPageSidebarProps = {
  setSidebarOpen: (arg: boolean) => void;
  setEditProfileOpen: (arg: boolean) => void;
};

const AskPageSidebarInner = ({
  setSidebarOpen,
  setEditProfileOpen,
}: AskPageSidebarProps) => {
  const navigate = useIdNavigate();
  const { currentProject } = useCurrentProject();

  const conversationsList =
    currentProject.project_question_status === "READY_FOR_QUESTIONS" ? (
      <ConversationsList setSidebarOpen={setSidebarOpen} />
    ) : null;

  return (
    <div className="flex flex-col gap-y-6 h-full max-h-full">
      <ProjectPicker />
      <span data-pendo-id="new-conversation-button">
        <Button
          color="success"
          fullWidth
          disabled={
            currentProject.project_question_status !== "READY_FOR_QUESTIONS"
          }
          onClick={() => {
            navigate("/conversations/new");
            setSidebarOpen(false);
          }}
          startIcon={<AddOutlined />}
        >
          New Conversation
        </Button>
      </span>
      {conversationsList}
      <div className="mt-auto">
        <UserIconMenu setEditProfileOpen={setEditProfileOpen} />
      </div>
    </div>
  );
};

export const AskPageSidebar = ({
  setSidebarOpen,
  setEditProfileOpen,
}: AskPageSidebarProps) => {
  return (
    <SuspenseBoundary>
      <AskPageSidebarInner
        setSidebarOpen={setSidebarOpen}
        setEditProfileOpen={setEditProfileOpen}
      />
    </SuspenseBoundary>
  );
};
