import { useContext, useState, useMemo } from "react";

// Hooks
import { useSearchProjectDocumentsCount } from "dataHooks";

// Components
import { FormControlLabel, Checkbox } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DocumentsList } from "../../DocumentsList";

// Contexts
import { CorpusFiltersContext } from "../../../CorpusFilters.context";
import { useDataAndDocumentsContext } from "../DataAndDocuments.context";

// Types
import { SyncConfigModules, Folder } from "@trunk-tools/txt-shared";

// Utils
import { cn } from "@trunk-tools/ui";
import { getFlattenedFolderTree } from "./DataAndDocuments.utils";

const DataAndDocumentsFolder = ({
  folder,
  uacProjectId,
}: {
  folder: Folder;
  uacProjectId: string;
}) => {
  const [manuallyExpanded, setManuallyExpanded] = useState(false);
  const [expansionToggledAt, setExpansionToggledAt] = useState<Date | number>(
    0,
  );

  const { onFilterBlockChange, onFilterBlockDelete } =
    useContext(CorpusFiltersContext);

  const { filters, debouncedSearchTerm, searchedAt } =
    useDataAndDocumentsContext();

  /**
   * The result of searching is to filter the list of folders based on
   * whether:
   *
   * - Any folder names match the search term
   * - Any documents in the folder match the search term
   *
   * As an efficiency, the query for document counts grouped by folder
   * ID only needs to happen once for the root folder.
   */
  const groupDocumentCountsByFolderId =
    !!debouncedSearchTerm && !folder.parent_folder_id;

  const { data: documentCountRes, isLoading: isDocumentCountLoading } =
    useSearchProjectDocumentsCount({
      uacProjectId,
      syncConfigModule: SyncConfigModules.Folders,
      searchTerm: debouncedSearchTerm,
      /**
       * If the user is searching for any folder or document, then
       * don't group by folder ID otherwise the backend will return
       * documents for just that folder. The desired result is a list
       * of all folders that have documents matching the search term.
       */
      uacFolderId: groupDocumentCountsByFolderId ? undefined : folder.id,
      groupedByFolderId: groupDocumentCountsByFolderId,
    });

  /**
   * If groupedByFolderId is true, then add up all the counts.
   * If groupedByFolderId is false, then return the count.
   */
  const documentCount = useMemo(() => {
    if (!documentCountRes || isDocumentCountLoading) {
      return 0;
    }

    if (groupDocumentCountsByFolderId) {
      return (documentCountRes.count_by_folder_id || []).reduce(
        (acc, count) => {
          return acc + (count.count || 0);
        },
        0,
      );
    }

    return documentCountRes.count;
  }, [groupDocumentCountsByFolderId, documentCountRes, isDocumentCountLoading]);

  /**
   * Build a folder tree from the document counts. If `groupDocumentCountsByFolderId`
   * is true, then the tree needs to be built based on:
   *
   * 1. Which folders have matching documents
   * 2. Which folder names match the search term
   * 3. All parent folders of folders that match [1] or [2]
   *
   * If `groupDocumentCountsByFolderId` is false, then the tree is all child folders.
   */
  const builtFolderTree = useMemo(() => {
    if (!groupDocumentCountsByFolderId) {
      return folder.child_folders;
    }

    const flattenedFolderTree = getFlattenedFolderTree({
      rootFolder: folder,
    });

    /**
     * Find any folders that have a matching document count, or have a name that
     * matches the search term.
     */
    const lowerCaseSearchTerm = (debouncedSearchTerm || "").toLowerCase();
    const matchingFolders = flattenedFolderTree.filter((folder) => {
      if (folder.name.toLowerCase().includes(lowerCaseSearchTerm)) {
        return true;
      }

      return documentCountRes?.count_by_folder_id?.find(
        (count) => count.folder_id === folder.id,
      );
    });

    if (!matchingFolders.length) {
      return [];
    }

    /**
     * From all the folders that match, compose a resultant folder tree.
     * The technique:
     *
     * 1. Walk the original tree (starting with the root `folder` variable
     *    passed into this component, the root) and exclude any folders that
     *    both:
     *    - Are not in the list of matching folders
     *    - Don't have any children that are in the list of matching folders
     * 2. Return the resultant folder tree
     *
     * The structure of the resultant folder tree is:
     *
     * {
     *   id: string;
     *   name: string;
     *   parent_folder_id: string;
     *   child_folders: Folder[];
     * }
     */

    // Helper function to check if a folder or any of its children match
    const hasMatchingChildren = ({ folder }: { folder: Folder }): boolean => {
      // Check if current folder matches
      if (matchingFolders.some((f) => f.id === folder.id)) {
        return true;
      }

      // Recursively check children
      return folder.child_folders?.some((child) =>
        hasMatchingChildren({ folder: child }),
      );
    };

    // Helper function to compose the filtered tree
    const composeFilteredTree = ({
      folder,
    }: {
      folder: Folder;
    }): Folder | null => {
      // If folder matches on ID or has matching children, include it
      if (
        matchingFolders.some((f) => f.id === folder.id) ||
        hasMatchingChildren({ folder })
      ) {
        // Create a copy of the folder with filtered children
        const filteredFolder: Folder = {
          ...folder,
          child_folders:
            folder.child_folders
              ?.map((child) => composeFilteredTree({ folder: child }))
              .filter((child): child is Folder => child !== null) || [],
        };
        return filteredFolder;
      }
      return null;
    };

    // Start composition from the root folder
    const filteredRoot = composeFilteredTree({ folder });
    return filteredRoot?.child_folders || [];
  }, [groupDocumentCountsByFolderId, documentCountRes, debouncedSearchTerm]);

  const foundFilter = useMemo(() => {
    return filters.find((filter) => {
      return (filter.folder_ids || []).includes(folder.id);
    });
  }, [filters]);

  const handleCheckboxChange = (checked: boolean) => {
    if (checked) {
      onFilterBlockChange({
        operation: "add",
        primaryAttribute: "folder_ids",
        primaryAttributeValue: folder.id,
        commonAttributes: {
          document_source_system: uacProjectId,
        },
      });
    } else if (foundFilter) {
      onFilterBlockDelete({ uuidKey: foundFilter._uuidKey });
    }
  };

  const hasChildren =
    Boolean(builtFolderTree?.length) || Boolean(documentCount);

  const expanded =
    (debouncedSearchTerm &&
      searchedAt > expansionToggledAt &&
      !isDocumentCountLoading &&
      hasChildren) ||
    manuallyExpanded;

  return (
    <div>
      <div className="flex flex-row items-center gap-2">
        <button
          onClick={() => {
            setExpansionToggledAt(new Date());
            setManuallyExpanded(!expanded);
          }}
          className={cn({ invisible: !hasChildren })}
        >
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </button>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(foundFilter)}
              onChange={(_, checked) => handleCheckboxChange(checked)}
            />
          }
          label={folder.name}
        />
      </div>
      {Boolean(hasChildren && expanded) && (
        <>
          <div className="ml-6">
            {(builtFolderTree || []).map((folder) => (
              <DataAndDocumentsFolder
                key={folder.id}
                folder={folder}
                uacProjectId={uacProjectId}
              />
            ))}
          </div>
          <div className="ml-13">
            <DocumentsList
              key={`folder-${folder.id}-documents`}
              filters={filters}
              searchTerm={debouncedSearchTerm}
              uacProjectId={uacProjectId}
              syncConfigModule={SyncConfigModules.Folders}
              uacFolderId={folder.id}
              showNoDocumentsMessage={folder.child_folders?.length === 0}
              documentCount={documentCount || 0}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const DataAndDocumentsFolders = ({
  uacProjectId,
  watchedFoldersTree,
}: {
  uacProjectId: string;
  watchedFoldersTree: Folder[];
}) => {
  return watchedFoldersTree.map((folder) => (
    <DataAndDocumentsFolder
      key={folder.id}
      folder={folder}
      uacProjectId={uacProjectId}
    />
  ));
};
