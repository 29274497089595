import { useContext, useMemo, useState } from "react";

// Components
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataAndDocumentsUACProjectModuleBranch } from "./DataAndDocumentsUACProjectModuleBranch.component";

// Hooks
import {
  useSearchProjectDocumentsCount,
  useUACProjectSyncConfig,
} from "dataHooks";

// Contexts
import {
  CorpusFiltersContext,
  UACProjectsByService,
} from "../../../CorpusFilters.context";
import { useDataAndDocumentsContext } from "../DataAndDocuments.context";

// Types
import { Folder, UACProjectSyncConfigType } from "@trunk-tools/txt-shared";

// Constants
import { SyncConfigModules } from "@trunk-tools/txt-shared";
import { SYNC_CONFIG_MODULE_LABEL_MAP } from "../../../CorpusFilters.constants";

// Utils
import { getFlattenedFolderTree } from "./DataAndDocuments.utils";

export const DataAndDocumentsUACProjectBranch = ({
  uacProject,
}: {
  uacProject: UACProjectsByService["uacProjects"][0];
}) => {
  const { searchTerm, debouncedSearchTerm, filters, searchedAt } =
    useDataAndDocumentsContext();
  const [manuallyExpanded, setManuallyExpanded] = useState<boolean>(
    Boolean(searchTerm),
  );
  const [expansionToggledAt, setExpansionToggledAt] = useState<Date | number>(
    0,
  );
  const expanded =
    (searchTerm && searchedAt > expansionToggledAt) || manuallyExpanded;

  const foundFilter = useMemo(() => {
    return filters.find((filter) => {
      if (!filter.document_source_system?.length) {
        return;
      }

      if (filter.document_source_system !== uacProject.id) {
        return false;
      }

      /**
       * Unlike other selection detection logic, this needs to determine
       * that no other primary attributes are selected to reflect that
       * the root UAC Project only was intentionally selected. Those
       * attributes include:
       *
       * - document_types
       * - document_ids
       * - folder_ids
       * - document_source_module
       */
      const otherAttributesSelected =
        filter.document_types?.length ||
        filter.document_ids?.length ||
        filter.folder_ids?.length ||
        !!filter.document_source_module;

      if (!otherAttributesSelected) {
        return true;
      }
    });
  }, [filters]);

  const isSelected = Boolean(foundFilter);
  const uacProjectId = uacProject.id;

  const uacProjectRes = useUACProjectSyncConfig({
    uac_project_id: uacProjectId,
  });
  const uacProjectSyncConfig: UACProjectSyncConfigType | { folders: Folder[] } =
    uacProjectRes?.data?.sync_config || { folders: [] };

  const folders = uacProjectSyncConfig.folders;

  /**
   * Walk the folder tree and only include folders where `watching`,
   * `has_watched_children`, or `is_implicitly_watched` is true.
   */
  const watchedFoldersTree = useMemo(() => {
    const filterWatchedFolders = (folder: Folder): Folder | null => {
      // If the folder is being watched or has watched children, process it
      if (
        folder.watching ||
        folder.has_watched_children ||
        folder.is_implicitly_watched
      ) {
        // Recursively filter children
        const filteredChildren = (folder.child_folders || [])
          .map(filterWatchedFolders)
          .filter((child) => !!child);

        // Return folder with filtered children
        return {
          ...folder,
          child_folders: filteredChildren,
        };
      }

      // If not watching and no watched children, exclude this folder
      return null;
    };

    return folders.map(filterWatchedFolders).filter((folder) => !!folder);
  }, [folders]);

  /**
   * Flatten lowercased folder names to determine if the user's
   * search term matches any folder name.
   */
  const flattenedFolderNames = useMemo(() => {
    if (!watchedFoldersTree.length) {
      return [];
    }

    const flattenedFolders = getFlattenedFolderTree({
      rootFolder: watchedFoldersTree[0],
    });

    return flattenedFolders.map((folder) => folder.name.toLowerCase());
  }, [watchedFoldersTree]);

  /**
   * Search all modules for a match against any document to
   * determine if a global "Not found" message needs to be
   * displayed.
   */
  const { data: documentCountRes } = useSearchProjectDocumentsCount({
    uacProjectId,
    searchTerm: debouncedSearchTerm,
  });

  const documentCount = documentCountRes?.count || 0;

  /**
   * Create an array of the attributes from uacProjectSyncConfig
   * that are `true`. This captures which modules are enabled.
   */
  const syncConfigAttributes: SyncConfigModules[] = [];
  Object.entries(uacProjectSyncConfig).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key as SyncConfigModules);
    }
    return acc;
  }, syncConfigAttributes);

  /**
   * Determine if any children of the UAC Project branch have matching
   * children against the search term. The following are searched on:
   *
   * - Module names
   * - Folder names
   * - At least one document name
   *
   * This is used to determine if the "No results" message should be
   * displayed.
   */
  const hasAnyMatchingChildren = useMemo(() => {
    if (documentCount > 0) {
      return true;
    }

    // If there is no search term, then all children are matching
    if (!debouncedSearchTerm) {
      return true;
    }

    const lowercaseSearchTerm = debouncedSearchTerm.toLowerCase();

    // Determine if any folder names match the search term
    const hasMatchingFolderName = flattenedFolderNames.some((folderName) => {
      return folderName.includes(lowercaseSearchTerm);
    });

    if (hasMatchingFolderName) {
      return true;
    }

    // Determine if any module names match the search term
    const moduleNames =
      syncConfigAttributes.map((module) =>
        SYNC_CONFIG_MODULE_LABEL_MAP[module].toLowerCase(),
      ) || [];
    const hasMatchingModuleName = moduleNames.some((moduleName) => {
      return moduleName.includes(lowercaseSearchTerm);
    });

    return hasMatchingModuleName;
  }, [debouncedSearchTerm, documentCount, syncConfigAttributes]);

  const { onFilterBlockChange, onFilterBlockDelete } =
    useContext(CorpusFiltersContext);
  const sourceSystem = uacProject.uac_account.source_system;

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <button
          onClick={() => {
            setExpansionToggledAt(new Date());
            setManuallyExpanded(!expanded);
          }}
        >
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </button>
        <FormControlLabel
          control={<Checkbox checked={isSelected} />}
          label={`${sourceSystem}: ${uacProject.name}`}
          onChange={(_event, checked) => {
            if (checked) {
              onFilterBlockChange({
                operation: "add",
                primaryAttribute: "document_source_system",
                primaryAttributeValue: uacProjectId,
              });
            } else if (foundFilter) {
              onFilterBlockDelete({ uuidKey: foundFilter._uuidKey });
            }
          }}
        />
      </div>
      {expanded && !hasAnyMatchingChildren && (
        <div className="ml-6 text-sm text-gray-500">No results</div>
      )}
      {expanded && hasAnyMatchingChildren && (
        <FormGroup className="ml-6 flex flex-col">
          {syncConfigAttributes.map((syncConfigModuleAttribute) => (
            <DataAndDocumentsUACProjectModuleBranch
              key={`${uacProjectId}-${syncConfigModuleAttribute}`}
              uacProjectId={uacProjectId}
              syncConfigModuleAttribute={syncConfigModuleAttribute}
              watchedFoldersTree={watchedFoldersTree}
              flattenedFolderNames={flattenedFolderNames}
            />
          ))}
        </FormGroup>
      )}
    </>
  );
};
