import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  BorderStyle,
  IParagraphOptions,
} from "docx";
import { marked, Token } from "marked";

// Types
type TokenType =
  | "blockquote"
  | "br"
  | "code"
  | "codespan"
  | "def"
  | "del"
  | "em"
  | "escape"
  | "heading"
  | "hr"
  | "html"
  | "image"
  | "link"
  | "list"
  | "list_item"
  | "paragraph"
  | "space"
  | "strong"
  | "table"
  | "text";

interface BaseToken extends Omit<Token, "type"> {
  type: TokenType;
}

interface TextToken extends BaseToken {
  text: string;
}

interface TokensContainer extends BaseToken {
  tokens: Token[];
}

interface HeadingToken extends TokensContainer {
  depth: number;
}

interface ListToken extends BaseToken {
  items: ListItemToken[];
}

interface ListItemToken extends BaseToken {
  tokens: Token[];
}

// Type guards
const isTextToken = (token: Token): token is TextToken & Token =>
  "text" in token;
const hasTokens = (token: Token): token is TokensContainer & Token =>
  "tokens" in token;
const isHeading = (token: Token): token is HeadingToken & Token =>
  token.type === "heading";
const isList = (token: Token): token is ListToken & Token =>
  token.type === "list";

// Formatting configurations
interface TextFormatting {
  bold?: boolean;
  italics?: boolean;
  strike?: boolean;
  font?: string;
  underline?: { type: "single" };
}

const getFormatting = (
  token: Token,
  parentFormatting: TextFormatting = {},
): TextFormatting => {
  const formatting: TextFormatting = { ...parentFormatting };
  const type = token.type as TokenType;

  if (type === "strong") formatting.bold = true;
  if (type === "em") formatting.italics = true;
  if (type === "del") formatting.strike = true;
  if (type === "code" || type === "codespan") formatting.font = "Courier New";
  if (type === "link") formatting.underline = { type: "single" };

  return formatting;
};

// Process inline tokens recursively
const processInlineTokens = (
  tokens: Token[],
  parentFormatting: TextFormatting = {},
): TextRun[] => {
  return tokens.flatMap((token): TextRun[] => {
    const formatting = getFormatting(token, parentFormatting);

    const createTextRun = (text: string): TextRun =>
      new TextRun({
        text: text.replace(/&#x20;/g, " "),
        ...formatting,
      });

    if (token.type === "br") {
      return [new TextRun({ text: "\n" })];
    }

    if (token.type === "image" && isTextToken(token)) {
      return [new TextRun({ text: `[Image: ${token.text}]` })];
    }

    if (hasTokens(token)) {
      return processInlineTokens(token.tokens, formatting);
    }

    if (isTextToken(token)) {
      return [createTextRun(token.text)];
    }

    console.warn(`Unhandled inline token type: ${token.type}`);
    return [];
  });
};

// Process block level tokens
const processBlockToken = (token: Token): Paragraph[] => {
  const createParagraph = (
    children: TextRun[],
    options: IParagraphOptions = {},
  ): Paragraph => new Paragraph({ children, ...options });

  if (isHeading(token)) {
    const children = hasTokens(token) ? processInlineTokens(token.tokens) : [];
    return [
      createParagraph(children, {
        heading: HeadingLevel[`HEADING_${token.depth}`],
        spacing: { before: 200, after: 200 },
      }),
    ];
  }

  if (token.type === "blockquote") {
    const children = hasTokens(token) ? processInlineTokens(token.tokens) : [];
    return [
      createParagraph(children, {
        indent: { left: 720 },
        spacing: { before: 120, after: 120 },
        border: {
          left: { size: 4, color: "888888", style: BorderStyle.SINGLE },
        },
      }),
    ];
  }

  if (token.type === "code" && isTextToken(token)) {
    return [
      createParagraph(
        [
          new TextRun({
            text: token.text.replace(/&#x20;/g, " "),
            font: "Courier New",
            size: 20,
          }),
        ],
        {
          spacing: { before: 120, after: 120 },
        },
      ),
    ];
  }

  if (isList(token)) {
    return token.items.map((item: ListItemToken, index: number) =>
      createParagraph(
        [
          new TextRun({ text: `${index + 1}. ` }),
          ...processInlineTokens(item.tokens),
        ],
        {
          indent: { left: 720 },
        },
      ),
    );
  }

  if (token.type === "hr") {
    return [createParagraph([new TextRun({ text: "\n" })])];
  }

  if (token.type === "paragraph") {
    const children = hasTokens(token) ? processInlineTokens(token.tokens) : [];
    return [createParagraph(children)];
  }

  console.warn(`Unhandled block token type: ${token.type}`);
  return [];
};

// Main conversion function
const convertMarkdownToDocx = (markdown: string): Paragraph[] => {
  const tokens = marked.lexer(markdown);
  return tokens.flatMap(processBlockToken);
};

// Public API
export const createWordDocument = (markdown: string): Document =>
  new Document({
    sections: [
      {
        properties: {},
        children: convertMarkdownToDocx(markdown),
      },
    ],
  });

export const downloadWordDocument = async (
  markdown: string,
  filename: string,
): Promise<void> => {
  const doc = createWordDocument(markdown);
  const blob = await Packer.toBlob(doc);

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
