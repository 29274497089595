import { useState } from "react";
import { TextInput } from "@trunk-tools/ui";
import { useCreateCorpusFilter } from "dataHooks";
import {
  CorpusFilter,
  CorpusFilterFiltersSchema,
} from "@trunk-tools/txt-shared";
import { LocalCorpusFilter } from "../CorpusFilters.types";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CloseRounded, CheckRounded } from "@mui/icons-material";

export const NewCorpusFilterModal = ({
  onClose,
  onFilterCreated,
  filters,
  visibility,
}: {
  onClose: () => void;
  onFilterCreated: ({ filter }: { filter: CorpusFilter }) => void;
  filters: LocalCorpusFilter["filters"];
  visibility: CorpusFilter["visibility"];
}) => {
  const [name, setName] = useState("");

  const { trigger: createCorpusFilter, isMutating } = useCreateCorpusFilter({
    onSuccess: (data) => {
      onFilterCreated({ filter: data });
      setName("");
    },
  });

  const handleSubmit = () => {
    createCorpusFilter({
      name,
      filters: CorpusFilterFiltersSchema.parse(filters),
      visibility: visibility,
      temporary_custom: false,
    });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Filter</DialogTitle>
      <DialogContent>
        <TextInput
          rounded
          name="new-corpus-filter-name"
          size="lg"
          placeholder="Type a name for this filter..."
          value={name}
          onChange={(nextValue) => {
            setName(nextValue);
          }}
        />
        <div className="flex justify-between mt-6">
          <Button
            onClick={onClose}
            color="secondary"
            size="large"
            startIcon={<CloseRounded />}
          >
            Cancel
          </Button>
          <div>
            <LoadingButton
              color="success"
              size="large"
              startIcon={<CheckRounded />}
              onClick={handleSubmit}
              disabled={!name}
              loading={isMutating}
              data-pendo-id="corpus_filter_create"
            >
              Save Filter
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
