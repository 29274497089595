import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useUpdateCorpusFilter } from "dataHooks";
import { CorpusFilter } from "@trunk-tools/txt-shared";
import { TextInput } from "@trunk-tools/ui";
import { CloseRounded, CheckRounded } from "@mui/icons-material";

export const RenameFilterModal = ({
  filter,
  onClose,
  onSuccess,
}: {
  filter: CorpusFilter;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [newName, setNewName] = useState(filter.name);

  // Reset name when modal opens with new filter
  useEffect(() => {
    setNewName(filter.name);
  }, [filter.name]);

  const { trigger: updateCorpusFilter, isMutating } = useUpdateCorpusFilter({
    onSuccess: () => {
      onSuccess();
    },
  });

  const handleRename = () => {
    if (newName.trim()) {
      updateCorpusFilter({
        ...filter,
        name: newName,
      });
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Rename Filter</DialogTitle>
      <DialogContent>
        <TextInput
          rounded
          name="new-corpus-filter-new-name"
          size="lg"
          placeholder="Type a new name for this filter..."
          value={newName}
          onChange={(nextValue) => {
            setNewName(nextValue);
          }}
        />
      </DialogContent>
      <DialogActions className="flex flex-row justify-between">
        <div>
          <Button
            color="secondary"
            size="large"
            onClick={onClose}
            disabled={isMutating}
            fullWidth
            startIcon={<CloseRounded />}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            color="success"
            size="large"
            onClick={handleRename}
            disabled={isMutating || !newName.trim() || newName === filter.name}
            data-pendo-id="corpus_filter_rename"
            fullWidth
            startIcon={<CheckRounded />}
          >
            Rename
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
