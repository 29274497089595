import { TrunkMessageAvatar } from "../icons/TrunkMessageAvatar";

interface ArtifactTileProps {
  title: string;
  onClick?: () => void;
  versions: number;
  date: Date;
}

const ArtifactTileMainContent = ({
  title,
  onClick,
  versions,
  date,
}: ArtifactTileProps) => {
  const formattedDate = new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div
      className={`flex gap-x-3 p-4 bg-[#0D1117] rounded-lg hover:bg-[#161B22] border border-transparent hover:border-white transition-all duration-200${
        onClick ? " cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      <TrunkMessageAvatar className="w-8 h-8 mr-2" fill="#FFB800" />
      <div className="flex flex-col gap-y-2">
        <div className="text-brand font-medium">{title}</div>
        <div className="flex items-center gap-x-2 text-white text-xs">
          <span>
            {versions} {versions > 1 ? "Versions" : "Version"}
          </span>
          <span className="text-gray-500">|</span>
          <span>{formattedDate}</span>
        </div>
      </div>
    </div>
  );
};

export const ArtifactTile = (props: ArtifactTileProps) => {
  return <ArtifactTileMainContent {...props} />;
};
