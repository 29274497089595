import { Folder } from "@trunk-tools/txt-shared";

export const getFlattenedFolderTree = ({
  rootFolder,
}: {
  rootFolder: Folder;
}): Folder[] => {
  const getAllChildFolders = ({ folder }: { folder: Folder }): Folder[] => {
    const childFolders = folder.child_folders || [];
    return [
      ...childFolders,
      ...childFolders.flatMap((child: Folder) =>
        getAllChildFolders({ folder: child }),
      ),
    ];
  };

  return [rootFolder, ...getAllChildFolders({ folder: rootFolder })];
};
