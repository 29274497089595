import useSWR from "swr";
import { api } from "./helpers/APIClient";
import useSWRMutation from "swr/mutation";
import { useGlobalState } from "dataHooks";
import { useBusinessProjectId } from "@/hooks/userUrlParams";

export const useQuestions = () => {
  const { setGlobalState, globalState } = useGlobalState();
  const { projectId } = useBusinessProjectId();

  const { data: questions, mutate: refetchQuestions } = useSWR(
    ["questions", projectId],
    async () => {
      if (!projectId) return [];

      const { questions } = await api.getUserQuestions({
        project_id: projectId,
      });
      return questions;
    },
    {
      suspense: true,
      // use this instead of a useEffect as this will run only
      // once, no matter how many hook calls
      onSuccess: () => {
        setGlobalState({
          updatedQuestionIds: [...(globalState.updatedQuestionIds || [])],
        });
      },
      refreshInterval: () => {
        return 0;
      },
    },
  );

  const fetch = async (_, { arg: question_text }) => {
    await api.projectQuestion({
      project_id: projectId,
      question_text,
    });

    refetchQuestions();
  };

  const { trigger: askQuestion } = useSWRMutation<
    unknown,
    unknown,
    [string, string],
    string
  >(["question", projectId], fetch);

  return {
    questions,
    askQuestion,
  };
};

export const useProjectQuestion = ({ questionId }: { questionId: string }) => {
  const { projectId } = useBusinessProjectId();

  return useSWR(
    ["question", projectId, questionId],
    async () => {
      if (!projectId || !questionId) return null;

      const { question } = await api.getUserProjectQuestion({
        project_id: projectId,
        question_id: questionId,
      });
      return question;
    },
    {
      suspense: true,
    },
  );
};

type UseQuestionDocumentProps = {
  questionId: string;
  documentId: string;
};

export const useQuestionDocumentResponseCitations = ({
  questionId,
  documentId,
}: UseQuestionDocumentProps) => {
  const { data } = useSWR(
    ["citations", questionId, documentId],
    async () => {
      return api.getQuestionDocumentResponseCitations({
        question_id: questionId,
        document_id: documentId,
      });
    },
    {
      suspense: true,
    },
  );

  return {
    citations: data.citations,
  };
};

export const useQuestionDocument = ({
  questionId,
  documentId,
}: UseQuestionDocumentProps) => {
  const { data: question } = useProjectQuestion({ questionId });
  const questionDocument = [
    ...(question?.question_documents || []),
    ...(question?.question_search_results || []),
  ].find((questionDocument) => questionDocument.document_id === documentId);

  const ext = questionDocument?.document.name.split(".").pop();

  const { data } = useSWR(
    ["getUserQuestionDocument", questionId, documentId],
    async () => {
      return api.getUserQuestionDocument({
        question_id: questionId,
        document_id: documentId,
      });
    },
    {
      suspense: true,
    },
  );

  return {
    url: data.document.url,
    ext,
    questionDocument,
  };
};

export const useQuestion = ({ questionId }: { questionId: string }) => {
  const fetch = async () => {
    const data = await api.getQuestion({
      question_id: questionId,
    });
    return data;
  };

  const { data: question, mutate } = useSWR(["question", questionId], fetch, {
    suspense: true,
  });

  return { question, refetchQuestion: mutate };
};
