import { Check, CloseRounded, Delete } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { showToast } from "@/components/Toasts/ToastService";
import { useDeleteProjectUsers } from "@/dataHooks/project.datahook";

interface RemoveUserModalProps {
  onClose: () => void;
  projectId: string;
  projectName: string;
  userId: string;
  userEmail: string;
  refetchProjectUsers: () => void;
}

export const RemoveUserModal = ({
  onClose,
  projectId,
  projectName,
  userId,
  userEmail,
  refetchProjectUsers,
}: RemoveUserModalProps) => {
  const { trigger: removeUsers, isMutating } = useDeleteProjectUsers({
    onSuccess: async () => {
      refetchProjectUsers();
      onClose();
      showToast({
        type: "success",
        message: "User Removed",
      });
    },
    onError: () => {
      onClose();
      showToast({
        type: "error",
        message: "Failed to remove user",
      });
    },
  });

  const onConfirmRemoval = () => {
    removeUsers({ user_ids: [userId], project_id: projectId });
  };

  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <DialogTitle>Remove User From Project</DialogTitle>
      <IconButton
        disabled={isMutating}
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          padding: 0,
        }}
      >
        <CloseRounded className="w-10 h-10" />
      </IconButton>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to remove user <b>{userEmail}</b> from the{" "}
          <b>{projectName}</b> project?
        </Typography>

        <div className="my-6 flex justify-end">
          <Button
            disabled={isMutating}
            variant="contained"
            color="secondary"
            className="mt-6"
            onClick={onClose}
            startIcon={<Check />}
          >
            NO, KEEP USER IN PROJECT
          </Button>
          <Button
            disabled={isMutating}
            variant="contained"
            color="error"
            className="mt-6 ml-5"
            onClick={onConfirmRemoval}
            startIcon={<Delete />}
          >
            YES, REMOVE USER FROM PROJECT
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
