import { SWRConfig, SWRConfiguration } from "swr";

// by default, all our SWR calls will be suspenseful
// HOWEVER :( we still need to pass { suspense: true ) to each useSWR call
// for typescript reasons
// let's put this silly little provider here just in case though
const config: SWRConfiguration = {
  suspense: true,
  revalidateOnMount: true,
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  // Set the default cache to 5 minutes
  dedupingInterval: 60000 * 5,
};

function SWRConfigProvider({ children }: { children: JSX.Element }) {
  return <SWRConfig value={config}>{children}</SWRConfig>;
}

export default SWRConfigProvider;
