import {
  ConversationItemMessage,
  MarkdownFormatter,
  ShortenedTileList,
} from "@trunk-tools/ui";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";
import { ArtifactTile } from "../ArtifactTile.component";

type CustomAgentResponseItemProps = {
  item: {
    response_text: string;
    artifacts: {
      id: string;
      title: string;
      created_at: Date;
      versions: {
        content: string;
        version: number;
        created_at: Date;
      }[];
    }[];
  };
  setViewingArtifact: (viewing: string) => void;
};

export const CustomAgentResponseItem = ({
  item,
  setViewingArtifact,
}: CustomAgentResponseItemProps) => {
  return (
    <ConversationItemMessage
      variant="brandGreyBackground"
      avatarProps={{
        variant: "v2yellowResponsive",
        img: ttMessageAvatarLogo,
        name: "TrunkText",
      }}
      isLastItem
      collapsible
      highlightOnHoverWhileClosed
      hideHeader
      headerContent={<p className="uppercase">response</p>}
      collapsedHeaderContent={<p className="uppercase">view response</p>}
    >
      <div className="flex flex-col gap-y-6">
        <MarkdownFormatter text={item.response_text} />
        {item.artifacts.length > 0 && (
          <div className="flex flex-col gap-y-2">
            <div className="text-xs uppercase text-gray-500">artifacts</div>
            <ShortenedTileList
              forceVertical={true}
              tiles={item.artifacts.map((artifact) => (
                <ArtifactTile
                  key={artifact.id}
                  title={artifact.title}
                  versions={artifact.versions.length}
                  date={artifact.versions[0].created_at}
                  onClick={() => setViewingArtifact(artifact.id)}
                />
              ))}
              maxTilesToDisplay={2}
              viewButtonText="show all artifacts"
              viewButtonVariant="primary"
              popupTitle="Conversation Artifacts"
            />
          </div>
        )}
      </div>
    </ConversationItemMessage>
  );
};
