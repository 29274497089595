export const copyQuestionAndAnswerToClipboard = async ({
  question,
  answer,
}: {
  question: string;
  answer?: string;
}) => {
  /**
   * NOTE: iOS treats text starting with a word followed by a colon as a URL,
   * so it encodes it as a URL. https://stackoverflow.com/a/79142985
   *
   * This code would previously have a colon at the end of "Question", which
   * would cause iOS to encode it as a URL. So the below structure is
   * intentional.
   */
  await navigator.clipboard.writeText(
    `Question\n\n${question}\n\nAnswer\n\n${answer}`,
  );
};
