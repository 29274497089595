import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useProjectUsers } from "@/dataHooks/agents.dataHook";
import { useEffect, useMemo, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { RemoveUserModal } from "./DeleteProjectUsersModal.component";
import { AddProjectUsersModal } from "./CreateProjectUsersModal.component";
import { useSafeProject } from "dataHooks";

export const ManageProjectUsers = () => {
  const {
    projectUsers: { users },
    refetchProjectUsers,
  } = useProjectUsers();

  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [beingRemovedUser, setBeingRemovedUser] = useState<
    undefined | { id: string; email: string }
  >();
  const projectId = useParams().projectId || "";
  const { data } = useSafeProject({ projectId });
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectId) {
      console.log("Project ID not valid, redirecting to manage page");
      navigate("/manage");
    }
  }, [projectId]);

  const userCols: GridColDef<(typeof flattenedUsers)[0]>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "NAME",
        renderCell: ({ row }) => `${row.first_name} ${row.last_name}`,
        width: 150,
      },
      {
        field: "role",
        headerName: "Project Permission",
        width: 200,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 300,
      },
      {
        field: "action",
        headerName: "ACTIONS",
        width: 400,
        renderCell: ({ row }) => (
          <div className="flex flex-row items-center gap-x-2 mt-2">
            <Button
              onClick={() => onClickRemoveFromProject(row)}
              size="small"
              color="error"
              variant="contained"
              startIcon={<Delete />}
            >
              REMOVE FROM PROJECT
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const onClickRemoveFromProject = (user: (typeof flattenedUsers)[0]) => {
    setBeingRemovedUser(user);
    setShowRemoveUserModal(true);
  };

  const flattenedUsers = users.map((user) => ({
    ...user,
    role: user.project_users[0]?.role,
    userId: user.id,
  }));

  return (
    <>
      <Button
        variant="contained"
        color="success"
        className="mb-4"
        onClick={() => setShowAddUsersModal(true)}
        startIcon={<Add />}
      >
        Add Users To Project
      </Button>
      {showRemoveUserModal && (
        <RemoveUserModal
          projectName={data?.name || ""}
          projectId={projectId}
          userEmail={beingRemovedUser?.email || ""}
          userId={beingRemovedUser?.id || ""}
          onClose={() => setShowRemoveUserModal(false)}
          refetchProjectUsers={refetchProjectUsers}
        />
      )}
      {showAddUsersModal && data && (
        <AddProjectUsersModal
          projectUserIds={flattenedUsers.map(({ userId }) => userId)}
          projectName={data.name}
          projectId={projectId}
          onClose={() => setShowAddUsersModal(false)}
          refetchProjectUsers={refetchProjectUsers}
        />
      )}
      <DataGrid rows={flattenedUsers} columns={userCols} hideFooter />
    </>
  );
};
