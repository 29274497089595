// MUI
import { Typography } from "@mui/material";

// Types
import { LocalCorpusFilter } from "../../CorpusFilters.types";
import { SingleFilterRefinement } from "../components/SingleFilterRefinement.component";
import { UACSystemLabel } from "./components/UACSystemLabel.component";

export const CorpusFilterRefinementPanel = ({
  corpusFilter,
}: {
  corpusFilter: Partial<LocalCorpusFilter>;
}) => {
  const corpusFilterFilters = corpusFilter.filters || [];
  /**
   * Handle filters without source systems, which means the filters
   * apply to all integrations.
   */
  const filtersWithoutSystems = corpusFilterFilters.filter(
    (filter) => !filter.document_source_system,
  );
  const filtersWithoutSystemsComponent = (
    <div className="flex flex-col gap-2">
      <Typography variant="subtitle1">All Integrations</Typography>
      <div className="flex flex-col gap-3">
        {filtersWithoutSystems.map((filter) => (
          <SingleFilterRefinement key={filter._uuidKey} filter={filter} />
        ))}
      </div>
    </div>
  );

  /**
   * Handle filters with source systems, which means the filters
   * apply to a specific integration.
   */
  const filtersWithSystems = corpusFilterFilters.filter((filter) =>
    Boolean(filter.document_source_system),
  );
  const groupedBySystem = filtersWithSystems.reduce((acc, filter) => {
    const key = filter.document_source_system!;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(filter);
    return acc;
  }, {} as Record<string, typeof corpusFilterFilters>);

  const filtersWithSystemsComponent = Object.entries(groupedBySystem).map(
    ([uacProjectId, filters]) => {
      return (
        <div key={uacProjectId}>
          <UACSystemLabel uacProjectId={uacProjectId} />
          <div className="flex flex-col gap-3 mt-2">
            {filters.map((filter) => (
              <SingleFilterRefinement key={filter._uuidKey} filter={filter} />
            ))}
          </div>
        </div>
      );
    },
  );

  return (
    <div>
      <div className="sticky top-0 z-10 bg-[#161f26] flex flex-row items-center justify-between">
        <Typography variant="h6" className="py-3">
          Selections
        </Typography>
      </div>
      <div className="flex flex-col gap-3">
        {filtersWithoutSystems.length === 0 &&
          filtersWithSystems.length === 0 && (
            <Typography variant="body1" className="text-gray-500">
              No selections
            </Typography>
          )}
        {filtersWithoutSystems.length > 0 && (
          <>{filtersWithoutSystemsComponent}</>
        )}
        {filtersWithSystems.length > 0 && <>{filtersWithSystemsComponent}</>}
      </div>
    </div>
  );
};
