import React, { ForwardedRef, forwardRef } from "react";

import { GradientBackdrop } from "@trunk-tools/ui";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import trunkToolsWhite from "public/trunk_tools_white.svg";
import {
  Button,
  ButtonProps,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import {
  AddOutlined,
  ArrowForwardIosOutlined,
  ArrowBackIosOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  FolderRounded,
  FilterListRounded,
  AddRounded,
} from "@mui/icons-material";
import ReorderIcon from "@mui/icons-material/Reorder";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect, useState } from "react";
import {
  useCreateQuestionBankAgent,
  useProjectUsers,
  useUpdateQuestionBankAgent,
} from "@/dataHooks/agents.dataHook";
import { useParams } from "react-router-dom";
import { useAgentConfig } from "@/dataHooks/agents.dataHook";
import { toCronInputValues } from "@trunk-tools/txt-shared";
import { useMe } from "dataHooks";
import { useResponsive } from "@/hooks/useResponsive";
import { CorpusFilters } from "@/components/CorpusFilters/CorpusFilters.component";
import { useCorpusFilter } from "dataHooks";
import { useUserAndSharedProjectCorpusFilters } from "@/dataHooks/corpusFilters.dataHook";
import { CorpusFilter } from "@trunk-tools/txt-shared";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { DEFAULT_CORPUS_FILTER } from "@/components/CorpusFilters/CorpusFilters.constants";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type DayOfWeek = "Su" | "Mo" | "Tu" | "We" | "Th" | "Fr" | "Sa";
type Frequency = "day" | "week" | "never";

type QuestionWithFilter = {
  id: string;
  question: string;
  current_corpus_filter_id: string | null;
  order: number;
};

type QuestionCardProps = {
  id: string;
  question: QuestionWithFilter | null;
  defaultFilterId: string | null;
  edit?: () => void;
  remove?: () => void;
  style?: React.CSSProperties;
};

const timeOptions = Array.from({ length: 48 }, (_, i) => {
  const localTimeZone = dayjs().tz(dayjs.tz.guess()).format("z");
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? "00" : "30";
  const ampm = hour < 12 ? "AM" : "PM";
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;

  return {
    label: `${hour12}:${minute} ${ampm} ${localTimeZone}`,
    value: `${hour}:${minute}`,
  };
});

const QuestionSet = ({
  questionSet,
  editQuestion,
  removeFromQuestionSet,
  defaultFilterId,
  reorderQuestion,
}: {
  questionSet: QuestionWithFilter[];
  editQuestion: (id: string) => void;
  removeFromQuestionSet: (id: string) => void;
  defaultFilterId: string | null;
  reorderQuestion: (id: string, newIndex: number) => void;
}) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [items, setItems] = useState(questionSet);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setItems(questionSet);
  }, [questionSet]);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });

      const newIndex = items.findIndex((item) => item.id === over?.id);
      reorderQuestion(active.id.toString(), newIndex);
    }
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveId(active.id.toString());
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={verticalListSortingStrategy}
      >
        {items.map((item) => (
          <SortableQuestionCard
            key={item.id}
            id={item.id}
            question={item}
            edit={() => editQuestion(item.id)}
            remove={() => removeFromQuestionSet(item.id)}
            defaultFilterId={defaultFilterId}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <SortableQuestionCard
            question={items.find((item) => item.id === activeId) || null}
            id={activeId}
            defaultFilterId={defaultFilterId}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

const SortableQuestionCard = ({
  question,
  edit,
  remove,
  defaultFilterId,
  id,
}: QuestionCardProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <QuestionCard
      id={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      defaultFilterId={defaultFilterId}
      question={question}
      edit={edit}
      remove={remove}
    />
  );
};

const QuestionCard = forwardRef(
  (
    {
      question,
      edit,
      remove,
      defaultFilterId,
      style,
      ...rest
    }: QuestionCardProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { data: corpusFilter } = useCorpusFilter({
      corpusFilterId: question?.current_corpus_filter_id,
    });
    const { data: agentCorpusFilter } = useCorpusFilter({
      corpusFilterId: defaultFilterId,
    });

    const index = question?.order ?? 0;

    return (
      <div
        className="group desktop:hover:bg-[#1A252D] b-1 rounded p-4 pl-12 relative font-poppins border border-black desktop:bg-[#141C22] bg-[#1A252D]"
        ref={ref}
        style={style}
        {...rest}
      >
        <ReorderIcon className="text-brand absolute left-4 desktop:opacity-0 desktop:group-hover:opacity-100 opacity-100 transition-opacity duration-200 ease-in-out" />
        <div className="flex flex-row justify-between items-center mb-4">
          <span className="text-sm text-gray-400">Question {index + 1}</span>
          <div className="flex flex-row gap-4 items-center desktop:opacity-0 desktop:group-hover:opacity-100 opacity-100 transition-opacity duration-200 ease-in-out">
            <EditOutlined
              className="cursor-pointer text-brand"
              onClick={() => {
                edit && edit();
              }}
            />
            <DeleteOutlineOutlinedIcon
              className="cursor-pointer text-red-600"
              onClick={() => {
                remove && remove();
              }}
            />
          </div>
        </div>
        <p className="mb-4">{question?.question}</p>
        <div className="text-sm">
          <div className="text-xs mb-2">SEARCH CONTEXT </div>
          <div>
            {corpusFilter?.name ??
              agentCorpusFilter?.name ??
              DEFAULT_CORPUS_FILTER.name}
          </div>
        </div>
      </div>
    );
  },
);

type TabHeadingProps = {
  text: string;
  selected: boolean;
  complete: boolean;
  onClick?: () => void;
};
const TabHeading = ({ text, selected, complete, onClick }: TabHeadingProps) => {
  return (
    <div
      className="uppercase desktop:mb-6 text-sm desktop:text-lg cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row gap-x-3">
        <FormControlLabel
          control={<Checkbox checked={complete} />}
          label={text}
          sx={{
            "& .MuiFormControlLabel-label": {
              opacity: selected ? 1 : 0.5,
            },
          }}
        />
      </div>
      {selected && (
        <div className="w-full h-2 left-0 top-[4px] relative">
          <div className="w-full h-0.5 left-0 top-[5px] relative bg-white" />
          <div className="w-2 h-2 left-1/2 top-0 relative bg-white rounded-full" />
        </div>
      )}
    </div>
  );
};

const YesNoModal = ({
  open,
  onClose,
  onYes,
  onNo,
  title,
  message,
  yesButtonText,
  noButtonText,
}: {
  open: boolean;
  onClose: () => void;
  onYes: () => void;
  onNo: () => void;
  title: string;
  message: string;
  yesButtonText?: string;
  noButtonText?: string;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button startIcon={<CloseOutlined />} onClick={onNo} color="secondary">
          {noButtonText || "No"}
        </Button>
        <Button startIcon={<CheckOutlined />} onClick={onYes} color="primary">
          {yesButtonText || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GlobalCorpusFilter = ({
  defaultFilterId,
  setDefaultFilterId,
  questionCount,
}: {
  defaultFilterId: string | null;
  setDefaultFilterId: (id: string | null) => void;
  questionCount: number;
}) => {
  const [corpusFiltersDialogOpen, setCorpusFiltersDialogOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingCorpusFilter, setPendingCorpusFilter] = useState<{
    corpusFilter?: CorpusFilter;
  } | null>(null);

  const { data: corpusFilters } = useUserAndSharedProjectCorpusFilters({
    visibility: "PROJECT",
  });

  const handleApply = ({ corpusFilter }: { corpusFilter?: CorpusFilter }) => {
    setPendingCorpusFilter({ corpusFilter });
    setShowConfirmModal(true);
    setCorpusFiltersDialogOpen(false);
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    setPendingCorpusFilter(null);
    setDefaultFilterId(pendingCorpusFilter?.corpusFilter?.id ?? null);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
    setPendingCorpusFilter(null);
  };

  const selectItems = [
    {
      label: DEFAULT_CORPUS_FILTER.name,
      value: DEFAULT_CORPUS_FILTER.id,
      icon: <FolderRounded className="mr-2" />,
    },
    ...(corpusFilters?.map((filter) => ({
      label: filter.name,
      value: filter.id,
      icon: <FilterListRounded className="mr-2" />,
    })) || []),
    {
      label: "Create new context",
      value: "new",
      icon: <AddRounded className="mr-2" />,
    },
  ];

  return (
    <div className="hidden desktop:block w-1/3 min-w-[250px] pr-6">
      <div className="uppercase mb-6 text-lg">global context</div>
      <div className="gap-y-4 text-md mb-8 font-poppins font-normal">
        <p className="mb-8">
          To apply the same Context as a default to all new and existing
          Questions in this Agent, select it here and click "Apply."
        </p>
        <p className="mb-8">
          (You can overwrite this selection on a Question by Question basis.)
        </p>
      </div>
      <div className="flex flex-col h-full">
        <div>
          <div className="text-white text-[10px] font-normal font-['Izoard Soft'] uppercase leading-[15px] mb-2">
            CONTEXT
          </div>
          <FormControl fullWidth>
            <Select
              value={defaultFilterId || DEFAULT_CORPUS_FILTER.id}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "new") {
                  setCorpusFiltersDialogOpen(true);
                } else {
                  handleApply({
                    corpusFilter: corpusFilters.find(
                      (item) => item.id === value,
                    ),
                  });
                }
              }}
              label="Select context"
            >
              {selectItems.map(
                ({ value: itemValue, label: itemLabel, icon }) => (
                  <MenuItem value={itemValue} key={itemValue}>
                    <div className="flex items-center">
                      {icon}
                      {itemLabel}
                    </div>
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </div>
        {corpusFiltersDialogOpen && (
          <CorpusFilters
            visibility="PROJECT"
            onDialogClose={() => setCorpusFiltersDialogOpen(false)}
            onApply={handleApply}
            initiallySelectedCorpusFilterId={undefined}
          />
        )}
      </div>
      <YesNoModal
        open={showConfirmModal}
        onClose={handleCancel}
        onYes={handleConfirm}
        onNo={handleCancel}
        title="Confirm Context Change"
        yesButtonText="Yes, change context"
        noButtonText="Cancel"
        message={`Are you sure you want to change the context of ${questionCount} questions to "${
          pendingCorpusFilter?.corpusFilter?.name ?? DEFAULT_CORPUS_FILTER.name
        }"?`}
      />
    </div>
  );
};

const CreateAgentFooter = ({
  canGoBack,
  canGoForward,
  canSave,
  onBack,
  onForward,
  onSave,
}: {
  canGoBack: boolean;
  canGoForward: boolean;
  canSave: boolean;
  onBack: () => void;
  onForward: () => void;
  onSave: () => void;
}) => {
  const idNavigate = useIdNavigate();
  const { isDesktop } = useResponsive();

  const commonButtonProps: ButtonProps = {
    variant: isDesktop ? "contained" : "iconbutton",
    size: isDesktop ? "large" : "medium",
  };

  return (
    <footer className="p-6 flex flex-row gap-x-4 desktop:gap-x-6">
      <Button
        {...commonButtonProps}
        onClick={() => {
          idNavigate("/agents");
        }}
        startIcon={isDesktop ? <CloseOutlined /> : undefined}
        color="secondary"
      >
        {isDesktop ? "Cancel" : <CloseOutlined />}
      </Button>
      <Button
        {...commonButtonProps}
        onClick={onBack}
        startIcon={isDesktop ? <ArrowBackIosOutlined /> : undefined}
        color="secondary"
        disabled={!canGoBack}
      >
        {isDesktop ? "Back" : <ArrowBackIosOutlined />}
      </Button>
      <span className="flex-grow"></span>
      <Button
        {...commonButtonProps}
        color="secondary"
        endIcon={isDesktop ? <ArrowForwardIosOutlined /> : undefined}
        onClick={onForward}
        disabled={!canGoForward}
      >
        {isDesktop ? "Next" : <ArrowForwardIosOutlined />}
      </Button>
      <Button
        {...commonButtonProps}
        onClick={onSave}
        startIcon={isDesktop ? <CheckOutlined /> : undefined}
        disabled={!canSave}
        color="primary"
      >
        {isDesktop ? "Save Changes" : <CheckOutlined />}
      </Button>
    </footer>
  );
};

const TabOneContents = ({
  currentQuestion,
  currentQuestionFilter,
  setCurrentQuestionFilter,
  addToQuestionSet,
  setCurrentQuestion,
  editing,
  cancelEdits,
  saveEditedQuestion,
  reorderQuestion,
  questionSet,
  editQuestion,
  removeFromQuestionSet,
  user,
  setUser,
  defaultFilterId,
}: {
  currentQuestion: string;
  currentQuestionFilter: string | null;
  setCurrentQuestionFilter: (value: string | null) => void;
  addToQuestionSet: () => void;
  setCurrentQuestion: (value: string) => void;
  editing: string | null;
  cancelEdits: () => void;
  saveEditedQuestion: () => void;
  reorderQuestion: (id: string, newIndex: number) => void;
  questionSet: QuestionWithFilter[];
  editQuestion: (id: string) => void;
  removeFromQuestionSet: (id: string) => void;
  user: string;
  setUser: (value: string) => void;
  defaultFilterId: string | null;
}) => {
  const { projectUsers } = useProjectUsers();
  const { me } = useMe();
  const [corpusFiltersDialogOpen, setCorpusFiltersDialogOpen] = useState(false);
  const { data: corpusFilters } = useUserAndSharedProjectCorpusFilters({
    visibility: "PROJECT",
  });

  let hasMe = false;
  const userOptions = projectUsers.users.map((user) => {
    if (user.id === me?.id) {
      hasMe = true;
    }
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    };
  });
  if (!hasMe) {
    userOptions.push({
      label: `${me?.first_name} ${me?.last_name}`,
      value: me?.id,
    });
  }

  const selectItems = [
    {
      label: DEFAULT_CORPUS_FILTER.name,
      value: DEFAULT_CORPUS_FILTER.id,
      icon: <FolderRounded className="mr-2" />,
    },
    ...(corpusFilters?.map((filter) => ({
      label: filter.name,
      value: filter.id,
      icon: <FilterListRounded className="mr-2" />,
    })) || []),
    {
      label: "Create new context",
      value: "new",
      icon: <AddRounded className="mr-2" />,
    },
  ];

  return (
    <>
      <div className="w-full flex flex-col desktop:flex-row gap-x-6">
        <div className="desktop:w-1/2">
          <div className="uppercase mb-6 text-lg">create a new question</div>
          <div>
            <div className="text-white text-[10px] font-normal font-['Izoard Soft'] uppercase leading-[15px] mb-2">
              CONTEXT
            </div>
            <FormControl fullWidth>
              <Select
                value={currentQuestionFilter || DEFAULT_CORPUS_FILTER.id}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === "new") {
                    setCorpusFiltersDialogOpen(true);
                  } else {
                    setCurrentQuestionFilter(
                      value === DEFAULT_CORPUS_FILTER.id ? null : value,
                    );
                  }
                }}
              >
                {selectItems.map(
                  ({ value: itemValue, label: itemLabel, icon }) => (
                    <MenuItem value={itemValue} key={itemValue}>
                      <div className="flex items-center">
                        {icon}
                        {itemLabel}
                      </div>
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          </div>
          <div className="text-white text-[10px] font-normal font-['Izoard Soft'] uppercase leading-[15px] mt-5">
            write a question or request
          </div>
          <TextareaAutosize
            className="w-full h-full text-base p-6 rounded tracking-normal pr-12 mt-3 mb-4"
            rows={1}
            style={{
              color: "#324353",
              border: "1px solid #F5BF40",
              boxShadow: "0px 1px 4px 0px #40300C7A, 0px 0px 8px 0px #F9DFA17A",
            }}
            value={currentQuestion}
            placeholder="Type a question..."
            onChange={(e) => setCurrentQuestion(e.target.value)}
          />
          <div className="flex flex-col gap-4">
            <div className="flex flex-row">
              <span className="hidden desktop:block flex-grow" />
              {editing === null ? (
                <div>
                  <Button
                    color="success"
                    onClick={addToQuestionSet}
                    disabled={currentQuestion === ""}
                    startIcon={<AddOutlined />}
                  >
                    Add To Question Set
                  </Button>
                </div>
              ) : (
                <div className="w-full flex flex-row justify-between">
                  <Button color="secondary" onClick={cancelEdits}>
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    onClick={saveEditedQuestion}
                    disabled={
                      currentQuestion ===
                        questionSet.find((q) => q.id === editing)?.question &&
                      currentQuestionFilter ===
                        questionSet.find((q) => q.id === editing)
                          ?.current_corpus_filter_id
                    }
                    startIcon={<AddOutlined />}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          {corpusFiltersDialogOpen && (
            <CorpusFilters
              visibility="PROJECT"
              onDialogClose={() => setCorpusFiltersDialogOpen(false)}
              onApply={({ corpusFilter }) => {
                setCurrentQuestionFilter(corpusFilter?.id ?? null);
                setCorpusFiltersDialogOpen(false);
              }}
              initiallySelectedCorpusFilterId={undefined}
            />
          )}
          <div className="mt-3 desktop:mt-0">
            <span>For user</span>
            <FormControl fullWidth>
              <Select
                value={user}
                name={"select_user"}
                onChange={(event) => {
                  const value = event.target.value;
                  setUser(value as string);
                }}
              >
                {userOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 w-full desktop:w-1/2">
          <div className="desktop:hidden w-full mt-4 mb-2 h-[2px] shadow-separator"></div>
          <div className="uppercase mb-6 text-lg">question set</div>
          {questionSet.length === 0 && (
            <div className="font-poppins font-light">No Questions Added</div>
          )}
          <QuestionSet
            reorderQuestion={reorderQuestion}
            questionSet={questionSet}
            editQuestion={editQuestion}
            removeFromQuestionSet={removeFromQuestionSet}
            defaultFilterId={defaultFilterId}
          />
        </div>
      </div>
    </>
  );
};

const TabTwoContents = ({
  agentName,
  setAgentName,
  frequencyUnit,
  setFrequencyUnit,
  time,
  setTime,
  selected,
  toggleDay,
}: {
  agentName: string;
  setAgentName: (value: string) => void;
  frequencyUnit: string;
  setFrequencyUnit: (value: Frequency) => void;
  time: string;
  setTime(value: string): void;
  selected: (day: DayOfWeek) => boolean;
  toggleDay: (day: DayOfWeek) => void;
}) => {
  return (
    <>
      <div className="desktop:w-[330px]">
        <p className="uppercase my-3">agent name</p>
        <FormControl fullWidth>
          <TextField
            value={agentName}
            onChange={(event) => setAgentName(event.target.value)}
            placeholder="Agent Name"
          />
        </FormControl>
        <p className="uppercase my-3">frequency</p>
        <div className="w-full h-14 justify-start items-center gap-2 inline-flex">
          <div className="flex-[30%]">Repeat every</div>
          <div className="flex-[30%]">
            <Select
              onChange={(event) => {
                const value = event.target.value;
                setFrequencyUnit(value as Frequency);
              }}
              value={frequencyUnit}
              name={""}
            >
              {["never", "day", "week"].map((unit) => (
                <MenuItem value={unit} key={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        {frequencyUnit === "week" && (
          <>
            <p className="uppercase my-3">repeat on</p>
            <div className="h-9 justify-start items-start gap-[13px] inline-flex">
              {(["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"] as DayOfWeek[]).map(
                (day) => (
                  <div
                    key={day}
                    onClick={() => toggleDay(day)}
                    className="w-9 h-9 relative cursor-pointer"
                  >
                    <div className="w-9 h-9 left-0 top-0 absolute justify-start items-start gap-2.5 inline-flex">
                      <div
                        className={`grow shrink basis-0 self-stretch rounded-full border-2 border-white ${
                          selected(day) ? "bg-[#005a9c]" : ""
                        }`}
                      />
                    </div>
                    <div className="w-9 left-0 top-[7px] absolute text-center text-white text-sm font-normal font-['Poppins'] leading-[21px]">
                      {day}
                    </div>
                  </div>
                ),
              )}
            </div>
          </>
        )}
        {frequencyUnit !== "never" && (
          <>
            <p className="uppercase my-3">run at</p>
            <div className="w-full h-9 justify-start items-start gap-[13px] inline-flex">
              <FormControl fullWidth>
                <Select
                  onChange={(event) => {
                    const value = event.target.value;
                    setTime(value);
                  }}
                  value={time}
                >
                  {timeOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const NewAgentPage = () => {
  const idNavigate = useIdNavigate();
  const { me } = useMe();

  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentQuestionFilter, setCurrentQuestionFilter] = useState<
    string | null
  >(null);
  const [editing, setEditing] = useState<string | null>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<Frequency>("week");
  const [time, setTime] = useState<string>("");
  const [repeatOn, setRepeatOn] = useState<DayOfWeek[]>([]);
  const [agentName, setAgentName] = useState<string>("");
  const [user, setUser] = useState<string>(me?.id || "");
  const [stage, setStage] = useState<number | null>(null);
  const [defaultFilterId, setDefaultFilterId] = useState<string | null>(null);

  const [questionSet, setQuestionSet] = useState<QuestionWithFilter[]>([]);

  const { agentId } = useParams<{ agentId: string }>();
  const { agentConfig, refetchAgentConfig } = useAgentConfig({
    agentId: agentId || "",
  });

  const handleDefaultCorpusFilterChange = (id: string | null) => {
    setDefaultFilterId(id);
    setCurrentQuestionFilter(id);
    updateAllQuestionFilters(id);
  };

  const updateAllQuestionFilters = (id: string | null) => {
    setQuestionSet(
      questionSet.map((q) => ({
        ...q,
        current_corpus_filter_id: id,
      })),
    );
  };

  useEffect(() => {
    if (me.role !== "SUPER_ADMIN") {
      setStage(2);
    }
    if (me.role === "SUPER_ADMIN" && stage === null) {
      setStage(1);
    }
  }, [me, stage]);

  useEffect(() => {
    if (agentConfig) {
      setAgentName(agentConfig.name);
      setQuestionSet(
        agentConfig.questions.map((q) => ({
          question: q.question,
          current_corpus_filter_id: q.current_corpus_filter_id,
          order: q.order,
          id: q.id,
        })),
      );
      const cron = toCronInputValues({
        cron: agentConfig.cron_expression || "",
        server_timezone: agentConfig.server_timezone,
      });
      setFrequencyUnit(cron.frequencyUnit);
      let time = "";
      if (cron.time) {
        time = cron.time.split(" ")[0];
      }
      setTime(time);
      setRepeatOn(cron.days.filter((day) => day !== undefined));
      setUser(agentConfig.user_id);
      setDefaultFilterId(agentConfig.current_corpus_filter_id);
    }
  }, [agentConfig]);

  const { createQuestionBankAgent } = useCreateQuestionBankAgent();
  const { updateQuestionBankAgent } = useUpdateQuestionBankAgent();

  const addToQuestionSet = () => {
    setQuestionSet([
      ...questionSet,
      {
        question: currentQuestion,
        current_corpus_filter_id: currentQuestionFilter,
        order: questionSet.length,
        id: Math.random().toString(),
      },
    ]);
    setCurrentQuestion("");
    setCurrentQuestionFilter(null);
  };

  const reorderQuestion = (id: string, newIndex: number) => {
    const newQuestionSet = arrayMove(
      questionSet,
      questionSet.findIndex((q) => q.id === id),
      newIndex,
    );
    newQuestionSet.forEach((q, i) => {
      q.order = i;
    });
    setQuestionSet(newQuestionSet);
  };

  const saveEditedQuestion = () => {
    const newQuestionSet = questionSet.map((q) =>
      q.id === editing
        ? {
            question: currentQuestion,
            current_corpus_filter_id: currentQuestionFilter,
            order: q.order,
            id: q.id,
          }
        : q,
    );
    setQuestionSet(newQuestionSet);
    setCurrentQuestion("");
    setCurrentQuestionFilter(null);
    setEditing(null);
  };

  const cancelEdits = () => {
    setCurrentQuestion("");
    setCurrentQuestionFilter(null);
    setEditing(null);
  };

  const removeFromQuestionSet = (id: string) => {
    const newQuestionSet = questionSet.filter((q) => q.id !== id);
    setQuestionSet(newQuestionSet);
  };

  const editQuestion = (id: string) => {
    const currentQuestion = questionSet.find((q) => q.id === id);
    if (currentQuestion) {
      setCurrentQuestion(currentQuestion.question);
      setCurrentQuestionFilter(currentQuestion.current_corpus_filter_id);
      setEditing(id);
    }
  };

  const selected = (day: DayOfWeek) => {
    return repeatOn.includes(day);
  };

  const toggleDay = (day: DayOfWeek) => {
    if (repeatOn.includes(day)) {
      setRepeatOn(repeatOn.filter((d) => d !== day));
    } else {
      setRepeatOn([...repeatOn, day]);
    }
  };

  return (
    <GradientBackdrop>
      <div className="flex flex-col h-full">
        <div className="px-6 flex-1 overflow-auto">
          <div className="h-25 flex flex-row gap-x-6 py-3 items-center">
            <img
              src={trunkToolsWhite}
              alt="trunk tools logo"
              className="w-13 h-8"
            />
            <p className="uppercase text-sm">agent manager</p>
          </div>
          <div className="flex flex-row gap-x-4 text-md desktop:text-xl mb-8">
            <p
              className="underline cursor-pointer"
              onClick={() => {
                idNavigate("/conversations/new");
              }}
            >
              Home
            </p>
            <p>&gt;</p>
            <p
              className="underline cursor-pointer"
              onClick={() => {
                idNavigate("/agents");
              }}
            >
              Manage Agents
            </p>
            <p>&gt;</p>
            <p>{agentName ? `${agentName}` : "Create New Agent"}</p>
          </div>

          <div className="flex flex-row gap-x-4 text-xl mb-8">
            {me.role === "SUPER_ADMIN" && (
              <TabHeading
                text="Question Set"
                selected={stage === 1}
                complete={questionSet.length > 0}
                onClick={() => setStage(1)}
              />
            )}
            <TabHeading
              text="Agent Info & Scheduling"
              selected={stage === 2}
              complete={agentName !== ""}
              onClick={() => setStage(2)}
            />
          </div>
          <div className="flex flex-row gap-x-12">
            {stage === 1 && (
              <GlobalCorpusFilter
                defaultFilterId={defaultFilterId}
                setDefaultFilterId={handleDefaultCorpusFilterChange}
                questionCount={questionSet.length}
              />
            )}
            <div className="w-full">
              {stage === 1 && (
                <TabOneContents
                  currentQuestion={currentQuestion}
                  currentQuestionFilter={currentQuestionFilter}
                  setCurrentQuestionFilter={setCurrentQuestionFilter}
                  addToQuestionSet={addToQuestionSet}
                  setCurrentQuestion={setCurrentQuestion}
                  editing={editing}
                  cancelEdits={cancelEdits}
                  saveEditedQuestion={saveEditedQuestion}
                  reorderQuestion={reorderQuestion}
                  questionSet={questionSet}
                  editQuestion={editQuestion}
                  removeFromQuestionSet={removeFromQuestionSet}
                  user={user}
                  setUser={setUser}
                  defaultFilterId={defaultFilterId}
                />
              )}
              {stage === 2 && (
                <TabTwoContents
                  agentName={agentName}
                  setAgentName={setAgentName}
                  frequencyUnit={frequencyUnit}
                  time={time}
                  setTime={setTime}
                  setFrequencyUnit={setFrequencyUnit}
                  selected={selected}
                  toggleDay={toggleDay}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-full mb-2 h-[2px] shadow-separator"></div>
        <div className="sticky bottom-0">
          <CreateAgentFooter
            canGoBack={stage !== 1 && me.role === "SUPER_ADMIN"}
            canGoForward={stage !== 2 && me.role === "SUPER_ADMIN"}
            canSave={
              questionSet.length > 0 &&
              agentName !== "" &&
              (frequencyUnit === "never" ||
                (time !== "" &&
                  (frequencyUnit === "day" || repeatOn.length > 0)))
            }
            onBack={() => setStage((stage || 2) - 1)}
            onForward={() => setStage((stage || 1) + 1)}
            onSave={() => {
              let t: string | null = time;
              let repeat: DayOfWeek[] | null = repeatOn;
              let frequency: Frequency | null = frequencyUnit;

              if (frequency === "never") {
                t = null;
                repeat = null;
                frequency = null;
              }
              if (agentId) {
                updateQuestionBankAgent({
                  time_zone: dayjs.tz.guess(),
                  agent_id: agentId,
                  questions: questionSet.map((question, i) => ({
                    question: question.question,
                    order: i,
                    current_corpus_filter_id: question.current_corpus_filter_id,
                  })),
                  name: agentName,
                  frequency_unit: frequency,
                  time: t,
                  repeat_on: repeat,
                  user: user,
                  current_corpus_filter_id: defaultFilterId,
                }).then(() => {
                  refetchAgentConfig();
                  idNavigate("/agents");
                });
              } else {
                createQuestionBankAgent({
                  time_zone: dayjs.tz.guess(),
                  questions: questionSet.map((question, i) => ({
                    question: question.question,
                    order: i,
                    current_corpus_filter_id: question.current_corpus_filter_id,
                  })),
                  name: agentName,
                  frequency_unit: frequency,
                  time: t,
                  repeat_on: repeat,
                  user: user,
                  current_corpus_filter_id: defaultFilterId,
                }).then(() => {
                  idNavigate(`/agents`);
                });
              }
            }}
          />
        </div>
      </div>
    </GradientBackdrop>
  );
};
