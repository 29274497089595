import { z } from "zod";
import { Check, CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Avatar, Form } from "@trunk-tools/ui";
import { showToast } from "@/components/Toasts/ToastService";
import { useUpdateProfile } from "dataHooks";

const profileFields = [
  ["First Name", "first_name"],
  ["Last Name", "last_name"],
  ["Job Title", "job_title"],
  ["Email Address", "email"],
  ["Phone Number", "phone"],
];

interface ProfileProps {
  onClose: () => void;
  onSuccess?: () => void;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    job_title: string | null;
    email: string;
    phone: string | null;
  };
}

export const Profile = ({ onClose, user, onSuccess }: ProfileProps) => {
  const { trigger: updateProfile, isMutating } = useUpdateProfile({
    onSuccess: async () => {
      onSuccess?.();
      onClose();
      showToast({
        type: "success",
        message: "Profile Updated!",
      });
    },
    onError: () => {
      showToast({
        type: "error",
        message: "Failed to update profile",
      });
    },
  });

  return (
    <>
      <Dialog open maxWidth="md" onClose={onClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
            padding: 0,
          }}
        >
          <CloseRounded className="w-10 h-10" />
        </IconButton>
        <DialogContent>
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-col justify-around">
              <Avatar
                initials={`${user.first_name[0]}${user.last_name[0]}`}
                variant="v2blue"
              />
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex flex-col justify-around mb-2">
                {user.first_name} {user.last_name}
              </div>
            </div>
          </div>
          <Form.Provider
            initialValues={{
              first_name: user.first_name || "",
              last_name: user.last_name || "",
              job_title: user.job_title || "",
              email: user.email || "",
              phone: user.phone || "",
            }}
            validators={{
              first_name: Form.validators.nonEmptyString(),
              last_name: Form.validators.nonEmptyString(),
              email: z.string().email(),
              phone: z.string().regex(/^\+1\d{10}$/, {
                message:
                  "Invalid Phone number. Must be in the format +1 followed by 10 digits",
              }),
            }}
            onSubmit={async (values) => {
              await updateProfile({ target_user_id: user.id, ...values });
            }}
            submitButtonText={false}
            children={(form) => (
              <>
                {profileFields.map(([displayName, propertyName]) => (
                  <div key={`form-field-${propertyName}`}>
                    <Typography variant="body2" className="mb-2 mt-8 uppercase">
                      {displayName}
                    </Typography>
                    <Form.Fields.Text
                      rounded
                      name={propertyName}
                      hasError={!!form.errors[propertyName]}
                      size="lg"
                    />
                    {form.errors[propertyName] && (
                      <div className="relative">
                        <Typography
                          variant="body2"
                          className="top-1 left-2 text-red-500 absolute"
                        >
                          {form.errors[propertyName]}
                        </Typography>
                      </div>
                    )}
                  </div>
                ))}

                <div className="flex justify-end mt-6">
                  <div>
                    <LoadingButton
                      disabled={isMutating || !form.isValid}
                      loading={form.isSubmitting}
                      startIcon={<Check />}
                      color="success"
                      type="submit"
                    >
                      Save Changes
                    </LoadingButton>
                  </div>
                </div>
              </>
            )}
          ></Form.Provider>
        </DialogContent>
      </Dialog>
    </>
  );
};
