import { useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import {
  BusinessPermission,
  getPermissibleBusinesses,
} from "@trunk-tools/txt-shared";
import { GradientBackdrop } from "@trunk-tools/ui";
import {
  BreadcrumbNavbar,
  BreadcrumbPropsType,
} from "@/components/BreadcrumbNavbar.component";
import { ManageProjectsTab } from "@/components/Manage/ManageProjectsTab.component";
import { ManageBusinessUsersTab } from "@/components/Manage/ManageUsersTab.component";
import { ManageProjectUsers } from "@/components/Manage/ManageProjectUsers.component";
import { useMe } from "@/dataHooks/auth.dataHook";

type ManageView =
  | {
      showTab: true;
      path: string;
      Component: () => React.JSX.Element;
      breadcrumbs: BreadcrumbPropsType[];
      label: string;
    }
  | {
      showTab: false;
      path: string;
      Component: () => React.JSX.Element;
      breadcrumbs: BreadcrumbPropsType[];
    };

const manageProjectUserView: ManageView = {
  showTab: false,
  path: "/manage/users",
  Component: ManageProjectUsers,
  breadcrumbs: [
    {
      type: "link",
      label: "Projects",
      redirect: "/manage/projects",
    },
    {
      label: "Users",
      type: "text",
    },
  ],
};

const manageProjectsView: ManageView = {
  showTab: true,
  label: "Projects",
  path: "/manage/projects",
  Component: ManageProjectsTab,
  breadcrumbs: [
    {
      label: "Projects",
      type: "text",
    },
  ],
};

const manageBusinessUsersView: ManageView = {
  showTab: true,
  label: "Users",
  path: "/manage/users",
  Component: ManageBusinessUsersTab,
  breadcrumbs: [
    {
      label: "Users",
      type: "text",
    },
  ],
};

const manageViews: ManageView[] = [
  manageProjectsView,
  manageBusinessUsersView,
  manageProjectUserView,
];

export const ManagePage = () => {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const { me: user } = useMe();

  const { accessibleView, hasBusinessPermission } = useMemo(() => {
    const { hasBusinessPermission } = getPermissibleBusinesses({
      user,
      permission: BusinessPermission.ManageBusinessUsers,
    });

    return {
      accessibleView: manageViews.filter(
        ({ path }) => hasBusinessPermission || path !== "/manage/users",
      ),
      hasBusinessPermission,
    };
  }, [user]);

  const activeView = useMemo(() => {
    if (projectId) {
      return manageProjectUserView;
    } else {
      return accessibleView.find(({ path }) => pathname && path === pathname);
    }
  }, [pathname, accessibleView]);

  const { path, Component, breadcrumbs, showTab } =
    activeView ?? accessibleView[0];

  return (
    <GradientBackdrop>
      <div className="px-6">
        <BreadcrumbNavbar
          title="Create & Manage Projects"
          breadcrumbs={breadcrumbs}
        />
        {hasBusinessPermission && showTab && (
          <Tabs className="my-2 border-b border-gray-800" value={path}>
            {accessibleView.map((view) => {
              if (view.showTab) {
                return (
                  <Tab
                    key={`${view.label.toLowerCase()}-tab`}
                    label={view.label}
                    value={view.path}
                    component={Link}
                    to={view.path}
                  />
                );
              } else {
                return undefined;
              }
            })}
          </Tabs>
        )}
        {Component && <Component />}
      </div>
    </GradientBackdrop>
  );
};
