import {
  useForm,
  PageSection,
  HeaderWithValue,
  Form,
  Stack,
  ThinkingAnimation,
} from "@trunk-tools/ui";
import { RichTreeView, TreeViewBaseItem } from "@mui/x-tree-view";
import { useUACProjectFull, useUACProjectStatus } from "dataHooks";
import { SyncInfoStatus } from "./SyncInfoStatus.component";
import { useEffect, useMemo, useState } from "react";

interface Props {
  uacProjectId: string;
}

export const SyncInfo = ({ uacProjectId }: Props) => {
  const { uacDisplayName, uacFeatureSupport, uacProjectSyncConfig, isLoading } =
    useUACProjectFull({
      uac_project_id: uacProjectId,
    });
  const { uacProjectStatus } = useUACProjectStatus({
    uac_project_id: uacProjectId,
  });

  if (isLoading) {
    return <ThinkingAnimation />;
  }

  return (
    <div>
      <PageSection header="Sync Info">
        <SyncInfoStatus
          displayName={uacDisplayName}
          documentStatusInfo={uacProjectStatus.documents_status}
        />
      </PageSection>
      <PageSection header="Connected Account">
        <HeaderWithValue header="name" value={uacDisplayName} />
        {uacFeatureSupport.rfi && (
          <HeaderWithValue
            header="RFIs"
            value={String(uacProjectSyncConfig?.rfis)}
          />
        )}
        {uacFeatureSupport.submittal && (
          <HeaderWithValue
            header="Submittals"
            value={String(uacProjectSyncConfig?.submittals)}
          />
        )}
        {uacFeatureSupport.specification_section && (
          <HeaderWithValue
            header="Specifications"
            value={String(uacProjectSyncConfig?.specs)}
          />
        )}
        {uacFeatureSupport.drawing && (
          <HeaderWithValue
            header="Drawings"
            value={String(uacProjectSyncConfig?.drawings)}
          />
        )}
        {uacFeatureSupport.meeting && (
          <HeaderWithValue
            header="Meetings"
            value={String(uacProjectSyncConfig?.meetings)}
          />
        )}
      </PageSection>
    </div>
  );
};

export const DataSets = ({ uacProjectId }: { uacProjectId: string }) => {
  const { uacFeatureSupport, uacProjectStatus } = useUACProjectFull({
    uac_project_id: uacProjectId,
  });

  const { rfi, submittal, specification_section, drawing } = uacFeatureSupport;

  if (!rfi && !submittal && !specification_section && !drawing) {
    return (
      <div className="text-center text-gray-600">
        No Additional Datasets Supported
      </div>
    );
  }

  const drawingLabel =
    uacProjectStatus.uac_project.uac_account.source_system === "Procore"
      ? "Drawings"
      : "Sheets";

  return (
    <div>
      <Stack gap={6}>
        {uacFeatureSupport.rfi && (
          <Form.Fields.Switch name="RFISync" label="RFIs" />
        )}
        {uacFeatureSupport.submittal && (
          <Form.Fields.Switch name="submittalsSync" label="Submittals" />
        )}
        {uacFeatureSupport.specification_section && (
          <Form.Fields.Switch name="specSync" label="Specifications" />
        )}
        {uacFeatureSupport.drawing && (
          <Form.Fields.Switch name="drawingSync" label={drawingLabel} />
        )}
        {uacFeatureSupport.form && (
          <Form.Fields.Switch name="formSync" label="Forms" />
        )}
        {uacFeatureSupport.issue && (
          <Form.Fields.Switch name="issueSync" label="Issues" />
        )}
        {uacFeatureSupport.meeting && (
          <Form.Fields.Switch name="meetingSync" label="Meetings" />
        )}
        {uacFeatureSupport.daily_logs && (
          <Form.Fields.Switch name="dailyLogSync" label="Daily Logs" />
        )}
      </Stack>
    </div>
  );
};

interface FindItemResult {
  item: TreeViewBaseItem | null;
  descendantIds: string[];
}

const findItemAndDescendants = ({
  items,
  targetId,
}: {
  items: TreeViewBaseItem[];
  targetId: string;
}): FindItemResult => {
  const result: FindItemResult = {
    item: null,
    descendantIds: [],
  };

  const traverse = (items: TreeViewBaseItem[]): boolean => {
    for (const item of items) {
      if (item.id === targetId) {
        result.item = item;
        // Get descendants of the found item
        if (item.children) {
          item.children.forEach((child) => {
            result.descendantIds.push(child.id);
            result.descendantIds.push(
              ...getItemDescendantsIds({ item: child }),
            );
          });
        }
        return true;
      }
      if (item.children && traverse(item.children)) {
        return true;
      }
    }
    return false;
  };

  traverse(items);
  return result;
};

const getItemDescendantsIds = ({ item }: { item: TreeViewBaseItem }) => {
  const ids: string[] = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds({ item: child }));
  });

  return ids;
};

export const Folders = ({ uacProjectId }: { uacProjectId: string }) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const { uacProjectSyncConfig, watchedFolderIds, isLoading } =
    useUACProjectFull({
      uac_project_id: uacProjectId,
    });
  const availableFolders = uacProjectSyncConfig?.folders;

  useEffect(() => {
    setExpandedItems(watchedFolderIds || []);
  }, [watchedFolderIds]);

  const form = useForm<{ selectedFolders: string[] }>();

  /**
   * Handlers
   */
  const handleItemSelectionToggle = (
    _event: React.SyntheticEvent<Element, Event>,
    itemId: string,
    isSelected: boolean,
  ) => {
    const { item, descendantIds } = findItemAndDescendants({
      items: retrofittedFoldersAndFilesTree,
      targetId: itemId,
    });

    if (!item) return;

    if (isSelected) {
      // Add the current item and all descendants
      const allSelectedFolders = new Set([
        ...form.values.selectedFolders,
        itemId,
        ...descendantIds,
      ]);
      form.setFieldValue("selectedFolders", Array.from(allSelectedFolders));
    } else {
      // Remove the current item and all descendants
      form.setFieldValue(
        "selectedFolders",
        form.values.selectedFolders.filter(
          (id) => id !== itemId && !descendantIds.includes(id),
        ),
      );
    }
  };

  /**
   * The folder structure from the backend is:
   * {
   *   id: string;
   *   name: string;
   *   child_folders: Folder[];
   * }
   *
   * MUI RichTreeView expects a structure like this:
   * {
   *   id: string;
   *   label: string;
   *   children: TreeItem[];
   * }
   *
   * The retrofit function converts the backend structure to the MUI structure.
   */
  const retrofittedFoldersAndFilesTree = useMemo(() => {
    if (!availableFolders) {
      return [];
    }

    const retrofitFolder = (folder) => ({
      id: folder.id,
      label: folder.name,
      children: folder.child_folders?.map(retrofitFolder) || [],
    });

    return availableFolders.map(retrofitFolder);
  }, [availableFolders]);

  if (isLoading) {
    return <ThinkingAnimation />;
  }

  return (
    <RichTreeView
      items={retrofittedFoldersAndFilesTree || []}
      selectedItems={form.values.selectedFolders}
      expandedItems={expandedItems}
      onExpandedItemsChange={(_event, expandedFolderIds) => {
        setExpandedItems(expandedFolderIds);
      }}
      onItemSelectionToggle={handleItemSelectionToggle}
      multiSelect
      checkboxSelection
    />
  );
};
