import { createTheme, ThemeOptions } from "@mui/material/styles";
import zIndexStyles from "@mui/material/styles/zIndex";
import CheckIcon from "@mui/icons-material/Check";
import { deepmerge } from "@mui/utils";
import { boxShadows, colors } from "@trunk-tools/ui";

import type {} from "@mui/x-tree-view/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    iconbutton: true;
  }

  interface ButtonPropsColorOverrides {
    secondarySuggestion: true;
  }
}

// Constants
const BASE_FONT_SIZE = 14;
const COMMON_BORDER_RADIUS = 6;
const DIALOG_TITLE_ACTION_PADDING = "24px";
const BUTTON_ICON_SPACING = "12px";
const BUTTON_BORDER_RADIUS = "3px";

const baseThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: "izoard, sans-serif",
    fontSize: BASE_FONT_SIZE,
    subtitle1: {
      fontSize: 15,
    },
  },
  shape: {
    borderRadius: COMMON_BORDER_RADIUS,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          // Otherwise the default font size is smaller
          fontSize: BASE_FONT_SIZE,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          transition: "all 200ms",
          whiteSpace: "nowrap",
          border: "1px solid transparent",
          textTransform: "uppercase",
          letterSpacing: "2px",
          borderRadius: BUTTON_BORDER_RADIUS,
        },
        // Default (medium) size
        sizeMedium: {
          padding: "12px",
          fontSize: "12px",
        },
        sizeLarge: {
          padding: "16px",
          fontSize: "14px",
          letterSpacing: "3px",
        },
        sizeSmall: {
          padding: "9px 10px",
          fontSize: "10px",
        },
        startIcon: {
          marginRight: BUTTON_ICON_SPACING,
        },
        endIcon: {
          marginLeft: BUTTON_ICON_SPACING,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          /**
           * NOTE: This is a workaround to fix a bug where the checkbox is not
           * being displayed correctly because a conflict with TailwindCSS
           * base/preflight configurations. Once the migration over to MUI is
           * complete and the Tailwind customizations (via ui.css) are removed,
           * this can be removed.
           */
          "& input": {
            width: "100%",
            height: "100%",
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          borderRadius: COMMON_BORDER_RADIUS,
          boxShadow: `0px 0px 16px 0px #0D121652`,
          "&.in-dialog": {
            zIndex: zIndexStyles.modal + 1,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        /**
         * Ensures the dialog is full width on mobile
         * to maximize available space.
         */
        paper: {
          margin: 0,
          width: "100%",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          textTransform: "uppercase",
          padding: DIALOG_TITLE_ACTION_PADDING,
          // Accommodate the close button
          paddingRight: "60px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0px 24px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: DIALOG_TITLE_ACTION_PADDING,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.text,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: "24px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          fontSize: "1rem",
          transition: "opacity 150ms",
          "&:not(.Mui-selected)": {
            opacity: 0.5,
          },
          "&:not(.Mui-selected):hover": {
            opacity: 0.75,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          marginTop: "4px",
        },
        content: {
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
};

const DARK_MODE_TOOLTIP_BACKGROUND_COLOR = "#000";
const BUTTON_ICON_BUTTON_SIZE = "40px";

export const darkThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: "#000",
    },
    text: {
      primary: "#fff",
    },
    primary: {
      main: colors.brand,
    },
    secondary: {
      main: colors.gray[100],
    },
    success: {
      main: colors.green[500],
    },
    error: {
      main: colors.red[700],
    },
  },
  components: {
    MuiPopper: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: colors.text,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          style: {
            backgroundColor: "#fff",
            color: colors.text,
          },
        },
      },
    },
    MuiDateCalendar: {
      defaultProps: {
        sx: {
          backgroundColor: "#fff",
          color: colors.text,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colors.text,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: colors.text,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors["tt-modal-bg"],
        },
      },
      defaultProps: {
        BackdropProps: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            backdropFilter: "blur(16px)",
          },
        },
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        color: "#fff",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: DARK_MODE_TOOLTIP_BACKGROUND_COLOR,
          color: "#fff",
          fontSize: "0.9rem",
        },
        arrow: {
          color: DARK_MODE_TOOLTIP_BACKGROUND_COLOR,
        },
        popper: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: colors.text,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: colors.gray[800],
            color: colors.gray[900],
            borderColor: colors.gray[900],
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
          },
        },
      },
      variants: [
        {
          props: {
            color: "primary",
          },
          style: {
            color: "#000",
            backgroundColor: colors.brand,
            "&:hover": {
              backgroundColor: colors["brand-dark"],
              boxShadow: boxShadows["brand-glow-hover"],
            },
            "&:active": {
              backgroundColor: colors["brand-light"],
              boxShadow: boxShadows["brand-glow-pressed"],
            },
          },
        },
        {
          props: {
            color: "secondary",
          },
          style: {
            color: "#0A545E",
            backgroundColor: colors.gray[100],
            "&:hover": {
              backgroundColor: colors.gray[200],
              boxShadow: boxShadows["brand-glow-hover"],
            },
            "&:active": {
              backgroundColor: colors.gray[300],
              boxShadow: boxShadows["brand-glow-pressed"],
            },
          },
        },
        {
          props: {
            color: "secondarySuggestion",
          },
          style: {
            color: "#0A545E",
            backgroundColor: colors.gray[100],
            boxShadow: boxShadows["secondary-glow"],
            "&:hover": {
              backgroundColor: colors.gray[200],
              boxShadow: boxShadows["brand-glow-hover"],
            },
            "&:active": {
              backgroundColor: colors.gray[300],
              boxShadow: boxShadows["brand-glow-pressed"],
            },
          },
        },
        {
          props: {
            color: "success",
          },
          style: {
            color: "#fff",
            backgroundColor: colors.green[500],
            "&:hover": {
              backgroundColor: colors.green[400],
              boxShadow: boxShadows["success-glow-hover"],
            },
            "&:active": {
              backgroundColor: colors.green[450],
              boxShadow: boxShadows["success-glow-pressed"],
            },
          },
        },
        {
          props: {
            color: "error",
          },
          style: {
            color: "#fff",
            backgroundColor: colors.red[700],
            "&:hover": {
              backgroundColor: colors.red[600],
              boxShadow: boxShadows["danger-glow-hover"],
            },
            "&:active": {
              backgroundColor: colors.red[500],
              boxShadow: boxShadows["danger-glow-pressed"],
            },
          },
        },
        {
          props: {
            variant: "iconbutton",
          },
          style: {
            minWidth: BUTTON_ICON_BUTTON_SIZE,
            height: BUTTON_ICON_BUTTON_SIZE,
            padding: "8px",
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-selected": {
            color: colors.brand,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.brand,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <CheckIcon
            className="text-white"
            sx={{ backgroundColor: colors.blue[500], borderRadius: "2px" }}
          />
        ),
      },
      styleOverrides: {
        root: {
          color: "#fff",
          "&.Mui-checked": {
            color: colors.blue[500],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Annoyingly, the default MUI behavior for inputs that
          // don't have a notch is to have the border be 0px and border
          // color be white. This isn't desired, so it's overridden here.
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: "rgb(0, 0, 0, 0.2)",
          },
          "& .MuiOutlinedInput-input": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: "#fff",
          fontSize: "1.25rem",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.brand,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-container--top [role=row]": {
            background: "transparent",
          },
          "& .MuiDataGrid-columnHeader": {
            textTransform: "uppercase",
          },
        },
        menuIconButton: {
          color: "#fff",
        },
        sortIcon: {
          color: "#fff",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "#fff",
        },
        actions: {
          button: {
            color: "#fff",
            "&:disabled": {
              opacity: 0.5,
            },
          },
        },
      },
    },
  },
};

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#000000",
    },
  },
};

export const darkTheme = createTheme(
  deepmerge(baseThemeOptions, darkThemeOptions),
);
export const lightTheme = createTheme(
  deepmerge(baseThemeOptions, lightThemeOptions),
);
