import { useContext, useEffect, useState } from "react";

// Components
import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { colors, TextInput } from "@trunk-tools/ui";
import { DocumentsList } from "./DocumentsList";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Hooks
import { useDebounceValue } from "usehooks-ts";

// Data Hooks
import { useSearchProjectDocumentsCount } from "dataHooks";

// Contexts
import { CorpusFiltersContext } from "../CorpusFilters.context";

// Types
import { LocalCorpusFilterFilter } from "../CorpusFilters.types";

// Constants
import { CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS } from "../CorpusFilterBlock.constants";

const DocumentClassifierTreeBranch = ({
  treeRootItem,
  selectedItems,
  filters,
  searchTerm,
}) => {
  const [manuallyExpanded, setManuallyExpanded] = useState<boolean>(false);
  const [expansionToggledAt, setExpansionToggledAt] = useState<Date | number>(
    0,
  );
  const [searchedAt, setSearchedAt] = useState<Date>(new Date());

  useEffect(() => {
    if (searchTerm) {
      setSearchedAt(new Date());
    }
  }, [searchTerm]);

  const { data: documentCountRes, isLoading: isDocumentCountLoading } =
    useSearchProjectDocumentsCount({
      type: treeRootItem.internalId,
      searchTerm,
    });

  const documentCount = documentCountRes?.count || 0;

  const expanded =
    (searchTerm &&
      searchedAt > expansionToggledAt &&
      !isDocumentCountLoading &&
      documentCount > 0) ||
    manuallyExpanded;

  let formControlLabel: React.ReactNode = treeRootItem.label;
  if (isDocumentCountLoading) {
    formControlLabel = (
      <>
        {formControlLabel}
        <span className="animate-pulse"> (..)</span>
      </>
    );
  } else {
    formControlLabel = `${formControlLabel} (${documentCount})`;
  }

  const isSelected = selectedItems.includes(treeRootItem.id);

  const { onFilterBlockChange } = useContext(CorpusFiltersContext);
  return (
    <>
      <div
        style={{ backgroundColor: colors["tt-modal-bg"] }}
        className="flex flex-row items-center gap-2  sticky top-0 z-[1]"
      >
        <button
          onClick={() => {
            setExpansionToggledAt(new Date());
            setManuallyExpanded(!expanded);
          }}
        >
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </button>
        <FormControlLabel
          control={<Checkbox checked={isSelected} />}
          label={formControlLabel}
          onChange={(_event, checked) => {
            if (checked) {
              onFilterBlockChange({
                operation: "add",
                primaryAttribute: "document_types",
                primaryAttributeValue: treeRootItem.id,
                commonAttributes: {},
              });
            } else {
              onFilterBlockChange({
                operation: "remove",
                primaryAttribute: "document_types",
                primaryAttributeValue: treeRootItem.id,
                commonAttributes: {},
              });
            }
          }}
        />
      </div>
      {expanded && (
        <div className="ml-7">
          <DocumentsList
            key={treeRootItem.internalId}
            filters={filters}
            type={treeRootItem.internalId}
            searchTerm={searchTerm}
            showNoDocumentsMessage
            documentCount={documentCount}
          />
        </div>
      )}
    </>
  );
};

export const DocumentClassifierTree = ({
  filters,
}: {
  filters: LocalCorpusFilterFilter[];
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 250);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    const newSelectedItems: string[] = [];
    filters.forEach((filter) => {
      if (filter.document_types?.length) {
        newSelectedItems.push(...filter.document_types);
      }
    });

    setSelectedItems(newSelectedItems);
  }, [filters]);

  return (
    <>
      <div className="sticky top-0 z-10">
        <TextInput
          rounded
          name="corpus-filter-documents-search"
          placeholder="Search Documents..."
          value={searchTerm}
          onChange={(nextValue) => {
            setSearchTerm(nextValue);
          }}
        />
      </div>
      <div className="overflow-y-auto mt-2">
        <FormGroup>
          {CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS.map((treeRootItem) => (
            <DocumentClassifierTreeBranch
              key={treeRootItem.id}
              treeRootItem={treeRootItem}
              selectedItems={selectedItems}
              searchTerm={debouncedSearchTerm}
              filters={filters}
            />
          ))}
        </FormGroup>
      </div>
    </>
  );
};
