import { createContext } from "react";
import {
  UACProject,
  UACAccountType,
} from "@trunk-tools/txt-shared/src/schemas/uac.schema";

export type UACProjectsByService = {
  id: string;
  serviceLabel: string;
  uacProjects: (UACProject & {
    uac_account: UACAccountType;
  })[];
};

type BaseFilterBlockParams = {
  uuidKey?: string;
  commonAttributes?: {
    document_source_system?: string;
    start_date?: string;
    end_date?: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PrimaryAttributeValue = any;
type PrimaryAttribute =
  | "document_source_system"
  | "document_types"
  | "document_ids"
  | "document_source_module"
  | "folder_ids";

type AddFilterBlockParams = BaseFilterBlockParams & {
  operation: "add";
  primaryAttribute: PrimaryAttribute;
  primaryAttributeValue: PrimaryAttributeValue;
};

type UpdateFilterBlockParams = BaseFilterBlockParams & {
  operation: "update";
  primaryAttribute?: PrimaryAttribute;
  primaryAttributeValue?: PrimaryAttributeValue;
};

type RemoveFilterBlockParams = BaseFilterBlockParams & {
  operation: "remove";
  primaryAttribute: PrimaryAttribute;
  primaryAttributeValue: PrimaryAttributeValue;
};

export type FilterBlockChangeParams =
  | AddFilterBlockParams
  | UpdateFilterBlockParams
  | RemoveFilterBlockParams;

interface CorpusFiltersContextType {
  allUACProjects: UACProjectsByService["uacProjects"];
  allUACProjectsByService: UACProjectsByService[];
  availableUACProjectsByService: UACProjectsByService[];
  onFilterBlockChange: (params: FilterBlockChangeParams) => void;
  onFilterBlockDelete: (params: { uuidKey: string }) => void;
}

export const CorpusFiltersContext = createContext<CorpusFiltersContextType>({
  allUACProjects: [],
  allUACProjectsByService: [],
  availableUACProjectsByService: [],
  onFilterBlockChange: () => void 0,
  onFilterBlockDelete: () => void 0,
});
