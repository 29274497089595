import { Check, CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Form } from "@trunk-tools/ui";
import { showToast } from "@/components/Toasts/ToastService";
import { useCreateBusiness, useUserBusinesses } from "dataHooks";
import { z } from "zod";

interface CreateNewBusinessModalProps {
  onClose: () => void;
  onSuccess?: () => void;
}

export const CreateNewBusinessModal = ({
  onClose,
  onSuccess,
}: CreateNewBusinessModalProps) => {
  const { businesses } = useUserBusinesses();

  const { trigger: createBusiness, isMutating } = useCreateBusiness({
    onSuccess: async () => {
      onSuccess?.();
      onClose();
      showToast({
        type: "success",
        message: "Business Created!",
      });
    },
    onError: () => {
      showToast({
        type: "error",
        message: "Failed to create Business",
      });
    },
  });

  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <DialogTitle>Create New Business</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          padding: 0,
        }}
      >
        <CloseRounded className="w-10 h-10" />
      </IconButton>
      <DialogContent>
        <Form.Provider
          initialValues={{
            name: "",
          }}
          validators={{
            name: z
              .string()
              .min(1, "Business name is required")
              .refine(
                (name) =>
                  !businesses.some(
                    (b) =>
                      b.name.toLocaleLowerCase() === name.toLocaleLowerCase(),
                  ),
                "A business with this name already exists",
              ),
          }}
          onSubmit={async (values) => {
            await createBusiness(values);
          }}
          submitButtonText={false}
          children={(form) => (
            <>
              <div>
                <Typography variant="body2" className="mb-2 mt-8 uppercase">
                  Business Name
                </Typography>
                <Form.Fields.Text
                  rounded
                  name="name"
                  hasError={!!form.errors.name && form.touched.name}
                  size="lg"
                />
                {form.errors.name && (
                  <div className="relative">
                    <Typography
                      variant="body2"
                      className="top-1 left-2 text-red-500 absolute"
                    >
                      {form.errors.name}
                    </Typography>
                  </div>
                )}
              </div>

              <div className="flex justify-end my-6">
                <div>
                  <LoadingButton
                    disabled={isMutating || !form.isValid}
                    loading={form.isSubmitting}
                    startIcon={<Check />}
                    color="success"
                    type="submit"
                  >
                    Create Business
                  </LoadingButton>
                </div>
              </div>
            </>
          )}
        ></Form.Provider>
      </DialogContent>
    </Dialog>
  );
};
