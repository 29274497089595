import { useContext, useEffect, useState } from "react";

// Components
import { TextInput } from "@trunk-tools/ui";
import { FormGroup } from "@mui/material";
import { DataAndDocumentsUACProjectBranch } from "./components/DataAndDocumentsUACProjectBranch.component";

// Hooks
import { useDebounceValue } from "usehooks-ts";

// Contexts
import { CorpusFiltersContext } from "../../CorpusFilters.context";
import { DataAndDocumentsProvider } from "./DataAndDocuments.context";

// Types
import { LocalCorpusFilterFilter } from "../../CorpusFilters.types";

export const DataAndDocumentsTree = ({
  filters,
}: {
  filters: LocalCorpusFilterFilter[];
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 250);

  const [searchedAt, setSearchedAt] = useState<Date>(new Date());
  useEffect(() => {
    if (searchTerm) {
      setSearchedAt(new Date());
    }
  }, [searchTerm]);

  const { allUACProjects } = useContext(CorpusFiltersContext);

  return (
    <DataAndDocumentsProvider
      searchTerm={searchTerm}
      debouncedSearchTerm={debouncedSearchTerm}
      filters={filters}
      searchedAt={searchedAt}
    >
      <div className="sticky top-0 z-10">
        <TextInput
          rounded
          name="corpus-filter-data-and-documents-search"
          placeholder="Search..."
          value={searchTerm}
          onChange={(nextValue) => {
            setSearchTerm(nextValue);
          }}
        />
      </div>
      <div className="overflow-y-auto mt-2">
        <FormGroup>
          {allUACProjects.map((uacProject) => (
            <DataAndDocumentsUACProjectBranch
              key={uacProject.id}
              uacProject={uacProject}
            />
          ))}
        </FormGroup>
      </div>
    </DataAndDocumentsProvider>
  );
};
