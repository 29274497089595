import { Check, CloseRounded } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import { showToast } from "@/components/Toasts/ToastService";
import { useCreateProjectUsers } from "@/dataHooks/project.datahook";
import { ProjectRole, UserWithProjectUsersType } from "@trunk-tools/txt-shared";
import { useBusinessUsers } from "dataHooks";
import { useState } from "react";
import { TextInput } from "@trunk-tools/ui";
import { useDataGridQuery } from "@/hooks/useDataGridQuery";
import { GridLogicOperator } from "@mui/x-data-grid";

interface AddProjectUsersModalProps {
  projectId: string;
  projectName: string;
  projectUserIds: string[];
  refetchProjectUsers: () => void;
  onClose: () => void;
}

export const AddProjectUsersModal = ({
  projectId,
  projectName,
  projectUserIds,
  refetchProjectUsers,
  onClose,
}: AddProjectUsersModalProps) => {
  const { setGridFilterModel, queryOptions } = useDataGridQuery();

  const { data } = useBusinessUsers(queryOptions);
  const users: UserWithProjectUsersType[] = data?.users || [];
  const [usersToAdd, setUsersToAdd] = useState<UserWithProjectUsersType[]>([]);
  const [userSearchString, setUserSearchString] = useState("");

  const onSearch = (searchTerm: string) => {
    setUserSearchString(searchTerm);
    setGridFilterModel({
      items: ["first_name", "last_name", "email"].map((field) => ({
        field,
        operator: "contains",
        value: searchTerm,
      })),
      logicOperator: GridLogicOperator.Or,
    });
  };

  const { trigger: addUsers, isMutating } = useCreateProjectUsers({
    onSuccess: async () => {
      refetchProjectUsers();
      onClose();
      showToast({
        type: "success",
        message: "User(s) added",
      });
    },
    onError: () => {
      showToast({
        type: "error",
        message: "Failed to add user(s)",
      });
    },
  });

  const handleAddUser = (user: UserWithProjectUsersType) => {
    setUsersToAdd((prevUsers) => [...prevUsers, user]);
  };

  const handleRemoveUser = (user: UserWithProjectUsersType) => {
    setUsersToAdd((prevUsers) => prevUsers.filter(({ id }) => id !== user.id));
  };

  const onClickAdd = () => {
    addUsers({
      users: usersToAdd.map(({ id }) => ({
        userId: id,
        role: ProjectRole.Member,
      })),
      project_id: projectId,
    });
  };

  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <DialogTitle>Add Users to "{projectName}" Project</DialogTitle>
      <IconButton
        disabled={isMutating}
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          padding: 0,
        }}
      >
        <CloseRounded className="w-10 h-10" />
      </IconButton>

      <div className="mx-6 mb-6">
        <TextInput
          rounded
          name="user-search"
          size="lg"
          placeholder="Search for people ..."
          value={userSearchString}
          onChange={(nextValue) => {
            onSearch(nextValue);
          }}
        />
        <div className="mt-2">
          {usersToAdd?.map((user) => (
            <Chip
              className="mx-1"
              variant="outlined"
              label={`${user.first_name} ${user.last_name}`}
              onDelete={() => handleRemoveUser(user)}
            />
          ))}
        </div>
      </div>

      <DialogContent>
        {users
          .filter(({ id }) => {
            //filter out users that are already part of the project, and that are already selected
            return (
              !projectUserIds.includes(id) &&
              !usersToAdd.some(({ id: _id }) => _id === id)
            );
          })
          .map((user) => (
            <div
              key={user.id}
              className="flex items-center gap-6 mb-2 p-2 hover:bg-tt-moonlight-blue-light cursor-pointer"
              onClick={() => handleAddUser(user)}
            >
              <div>
                <Typography variant="subtitle1">
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography variant="caption">{user.email}</Typography>
              </div>
            </div>
          ))}
      </DialogContent>
      <div className="flex justify-end py-6 pr-6 border-t border-black">
        <div>
          <Button
            disabled={usersToAdd.length === 0 || isMutating}
            variant="contained"
            color="success"
            onClick={onClickAdd}
            startIcon={<Check />}
          >
            Add To Project
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
