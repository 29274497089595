import useSWR from "swr";
import { api } from "./helpers/APIClient";
import { useMaybeCurrentProject } from "./project.datahook";
import { CreateBusinessBodyType } from "@trunk-tools/txt-shared";
import useSWRMutation from "swr/mutation";

export const useCreateBusiness = (createBusinessCallbacks: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const createBusinessFetch = async (
    key: unknown,
    { arg }: { arg: CreateBusinessBodyType },
  ) => {
    const result = await api.createBusiness({
      ...arg,
    });
    if (!result) {
      throw new Error("Failed to create project");
    }
    return result;
  };

  return useSWRMutation(
    ["createBusiness"],
    createBusinessFetch,
    createBusinessCallbacks,
  );
};

export const useUserBusinesses = () => {
  const fetch = async (): Promise<
    Awaited<ReturnType<typeof api.userBusinesses>>["businesses"]
  > => {
    // user might not be logged in
    try {
      const data = await api.userBusinesses({});
      return data.businesses;
    } catch (error) {
      return [];
    }
  };

  const { data: businesses } = useSWR("userBusinesses", fetch, {
    suspense: true,
  });

  return {
    businesses,
  };
};

export const useMaybeCurrentBusiness = () => {
  const { currentProject } = useMaybeCurrentProject();
  const { businesses } = useUserBusinesses();

  const currentBusiness = businesses.find(
    (b) => b.id === currentProject?.business_id,
  );

  return { currentBusiness };
};

export const useMaybeUserBusinesses = () => {
  const fetch = async () => {
    try {
      const data = await api.userBusinesses({});
      return data.businesses;
    } catch (error) {
      // not a superadmin user or not logged in, no problem
      return [];
    }
  };

  const { data: businesses, mutate: refreshUserBusinesses } = useSWR(
    "userBusinesses",
    fetch,
    {
      suspense: true,
    },
  );

  return {
    businesses,
    refreshUserBusinesses,
  };
};
