import { Check, CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  Chip,
  Box,
} from "@mui/material";
import { Form } from "@trunk-tools/ui";
import { showToast } from "@/components/Toasts/ToastService";
import { useInvite, useUserProjects } from "dataHooks";
import { z } from "zod";

const projectFields = [
  ["First Name", "first_name"],
  ["Last Name", "last_name"],
  ["Email", "email"],
  ["Phone", "phone"],
  ["Job Title", "job_title"],
  ["Projects", "project_ids"],
];

export const InviteUserModal = ({ onClose }: { onClose: () => void }) => {
  const onInviteUser = ({
    first_name,
    last_name,
    email,
    phone,
    job_title,
    project_ids,
  }: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    job_title: string;
    project_ids: string[];
  }) => {
    inviteUser({
      first_name,
      last_name,
      email,
      phone,
      job_title,
      project_ids,
      project_role: "MEMBER",
    });
  };

  const { projects: userProjects } = useUserProjects({
    sortByName: "asc",
  });

  const { trigger: inviteUser, isMutating } = useInvite({
    onSuccess: async () => {
      onClose();
      showToast({
        type: "success",
        message: "User invited!",
      });
    },
    onError: () => {
      showToast({
        type: "error",
        message: "Failed to invite user",
      });
    },
  });

  return (
    <Dialog open maxWidth="md" onClose={onClose}>
      <DialogTitle>Invite New User</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        size="large"
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
          padding: 0,
        }}
      >
        <CloseRounded className="w-10 h-10" />
      </IconButton>
      <DialogContent>
        <Form.Provider
          initialValues={
            {
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              job_title: "",
              project_ids: [],
            } as {
              first_name: string;
              last_name: string;
              email: string;
              phone: string;
              job_title: string;
              project_ids: string[];
            }
          }
          validators={{
            first_name: Form.validators.nonEmptyString(),
            last_name: Form.validators.nonEmptyString(),
            email: z.string().email(),
            phone: z.string().regex(/^\+1\d{10}$/, {
              message:
                "Invalid Phone number. Must be in the format +1 followed by 10 digits",
            }),
            job_title: Form.validators.nonEmptyString(),
          }}
          onSubmit={async (values) => {
            await onInviteUser(values);
          }}
          submitButtonText={false}
          children={(form) => (
            <>
              {projectFields.map(([displayName, propertyName]) => {
                let inner: JSX.Element = (
                  <Form.Fields.Text
                    rounded
                    name={propertyName}
                    hasError={
                      !!form.errors[propertyName] && form.touched[propertyName]
                    }
                    size="lg"
                  />
                );
                if (propertyName === "project_ids") {
                  inner = (
                    <FormControl fullWidth>
                      <Select
                        multiple
                        value={form.values[propertyName]}
                        onChange={(e) =>
                          form.setFieldValue(propertyName, e.target.value)
                        }
                        label="Select Role"
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected
                              .map(
                                (id) =>
                                  userProjects.find(({ id: pid }) => pid === id)
                                    ?.name,
                              )
                              .map((projectName) => (
                                <Chip
                                  size="small"
                                  color="info"
                                  variant="filled"
                                  key={projectName}
                                  label={projectName}
                                />
                              ))}
                          </Box>
                        )}
                      >
                        {userProjects.map(({ name, id }) => (
                          <MenuItem value={id}>
                            {" "}
                            <Checkbox
                              checked={form.values[propertyName].includes(id)}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }

                return (
                  <div key={`form-field-${propertyName}`}>
                    <Typography variant="body2" className="mb-2 mt-8 uppercase">
                      {displayName}
                    </Typography>
                    {inner}
                    {form.errors[propertyName] &&
                      form.touched[propertyName] && (
                        <div className="relative">
                          <Typography
                            variant="body2"
                            className="top-1 left-2 text-red-500 absolute"
                          >
                            {form.errors[propertyName]}
                          </Typography>
                        </div>
                      )}
                  </div>
                );
              })}

              <div className="flex justify-end my-6">
                <div>
                  <LoadingButton
                    disabled={isMutating || !form.isValid}
                    loading={form.isSubmitting}
                    startIcon={<Check />}
                    color="success"
                    type="submit"
                  >
                    Invite User
                  </LoadingButton>
                </div>
              </div>
            </>
          )}
        ></Form.Provider>
      </DialogContent>
    </Dialog>
  );
};
