import useSWR from "swr";
import { api } from "./helpers/APIClient";
import { useBusinessProjectId } from "@/hooks/userUrlParams";
import { useParams } from "react-router-dom";
import { DocumentTypes, SyncConfigModules } from "@trunk-tools/txt-shared";
import useSWRMutation from "swr/mutation";
import { ProjectRole } from "@trunk-tools/txt-shared";

export const useProjectDocumentsStatus = () => {
  const { currentProject } = useCurrentProject();

  const {
    data: { documents_status },
  } = useSWR(
    "alskdjflaksjdflaskdf",
    () => api.getProjectDocumentsStatus({ project_id: currentProject.id }),
    {
      suspense: true,
    },
  );

  return { documents_status };
};

export const useProjectDocumentVersions = () => {
  const { projectId } = useBusinessProjectId();
  const {
    data: { versions },
  } = useSWR(
    "projects/documents/versions",
    () => api.getProjectDocumentParserVersions({ project_id: projectId }),
    {
      suspense: true,
    },
  );

  return {
    versions,
  };
};

export const useMaybeCurrentProject = () => {
  const { projects } = useMaybeUserProjects({});
  const { projectId } = useBusinessProjectId();

  const currentProject = projects.find((p) => p.id === projectId);

  return { currentProject };
};

export const useCurrentProject = () => {
  const { projects } = useUserProjects({});
  const { projectId } = useBusinessProjectId();

  const currentProject = projects.find((p) => p.id === projectId);

  // edge case: if a user follows a URL to a project they don't have
  // access to their current project can be undefined
  if (!currentProject) {
    throw new Error(
      "This hook should only be used in the context of an element wrapped in the project route wrapper",
    );
  }

  return { currentProject };
};

export const useUserBelongsToProject = () => {
  const { projects } = useUserProjects({});
  const { projectId } = useParams<{ projectId: string }>();

  return {
    userBelongsToProject: projects.some((p) => p.id === projectId),
  };
};

export const useUserProjects = ({
  sortByName,
}: {
  sortByName?: "asc" | "desc";
}) => {
  const fetch = async (): Promise<
    Awaited<ReturnType<typeof api.userProjects>>["projects"]
  > => {
    const data = await api.userProjects({
      sortByName,
    });
    return data.projects;
  };

  const { data: projects } = useSWR(["userProjects", sortByName], fetch, {
    suspense: true,
  });

  return {
    projects,
  };
};

export const useMaybeUserProjects = ({
  sortByName,
}: {
  sortByName?: "asc" | "desc";
}) => {
  const fetch = async (): Promise<
    Awaited<ReturnType<typeof api.userProjects>>["projects"]
  > => {
    try {
      const data = await api.userProjects({
        sortByName,
      });
      return data.projects;
    } catch {
      return [];
    }
  };

  const { data: projects } = useSWR(["maybeUserProjects", sortByName], fetch, {
    suspense: true,
  });

  return {
    projects,
  };
};

export const useSampleQuestions = ({
  num_samples = 3,
}: {
  num_samples?: number;
}) => {
  const { currentProject } = useCurrentProject();

  const { data: sampleQuestions } = useSWR(
    `${currentProject.id}/sample-questions`,
    () =>
      api.getSampleQuestions({
        project_id: currentProject.id,
        num_samples,
      }),
    {
      suspense: true,
    },
  );

  return {
    sampleQuestions,
  };
};

export const useSearchProjectDocuments = ({
  searchTerm,
  skip,
  take,
  type,
  uacProjectId,
  syncConfigModule,
  uacFolderId,
}: {
  searchTerm?: string;
  skip?: number;
  take?: number;
  type?: DocumentTypes;
  uacProjectId?: string;
  syncConfigModule?: SyncConfigModules;
  uacFolderId?: string;
}) => {
  const { currentProject } = useCurrentProject();

  const { data, isLoading, error } = useSWR(
    [
      `${currentProject.id}/documents/search`,
      searchTerm,
      skip,
      take,
      type,
      uacProjectId,
      syncConfigModule,
      uacFolderId,
    ],
    () =>
      api.searchProjectDocuments({
        project_id: currentProject.id,
        take,
        skip,
        search: searchTerm,
        type,
        uac_project_id: uacProjectId,
        sync_config_module: syncConfigModule,
        uac_folder_id: uacFolderId,
      }),
    {
      suspense: false,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};

export const useSearchProjectDocumentsCount = ({
  searchTerm,
  type,
  uacProjectId,
  syncConfigModule,
  uacFolderId,
  groupedByFolderId,
}: {
  searchTerm?: string;
  type?: DocumentTypes;
  uacProjectId?: string;
  syncConfigModule?: SyncConfigModules;
  uacFolderId?: string;
  groupedByFolderId?: boolean;
}) => {
  const { currentProject } = useCurrentProject();

  const { data, isLoading, error } = useSWR(
    [
      `${currentProject.id}/documents/search/count`,
      searchTerm,
      type,
      uacProjectId,
      syncConfigModule,
      uacFolderId,
      groupedByFolderId,
    ],
    () =>
      api.searchProjectDocumentsCount({
        project_id: currentProject.id,
        search: searchTerm,
        type,
        uac_project_id: uacProjectId,
        sync_config_module: syncConfigModule,
        uac_folder_id: uacFolderId,
        grouped_by_folder_id: groupedByFolderId,
      }),
    {
      suspense: false,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};

export const useProjects = ({
  skip,
  take,
}: {
  skip?: number;
  take?: number;
} = {}) =>
  useSWR(
    ["projects", skip, take],
    async (): Promise<Awaited<ReturnType<typeof api.projects>>> => {
      const data = await api.projects({
        skip,
        take,
      });
      return data;
    },
  );

export const useSafeProject = ({ projectId }: { projectId: string }) => {
  try {
    return useSWR(
      ["project", projectId],
      async (): Promise<Awaited<ReturnType<typeof api.getProject>>> => {
        const data = await api.getProject({ project_id: projectId });
        return data;
      },
    );
  } catch (e) {
    return { data: undefined };
  }
};

export const useCreateProjectUsers = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const createProjectUsersFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: {
        project_id: string;
        users: { role: ProjectRole; userId: string }[];
      };
    },
  ) => {
    const result = await api.createProjectUsers(arg);
    if (!result) {
      throw new Error("Failed to remove users from project");
    }
    return result;
  };

  return useSWRMutation(["createProjectUsers"], createProjectUsersFetch, {
    onSuccess,
    onError,
  });
};

export const useDeleteProjectUsers = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const useDeleteProjectUsersFetch = async (
    key: unknown,
    {
      arg,
    }: {
      arg: {
        project_id: string;
        user_ids: string[];
      };
    },
  ) => {
    const result = await api.deleteProjectUsers(arg);
    if (!result || !result.success) {
      throw new Error("Failed to remove users from project");
    }
    return result;
  };

  return useSWRMutation(["deleteProjectUsers"], useDeleteProjectUsersFetch, {
    onSuccess,
    onError,
  });
};
