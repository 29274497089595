import dayjs from "dayjs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Spinner } from "@trunk-tools/ui";
import { useMemo } from "react";

interface Props {
  displayName: string;
  documentStatusInfo: {
    processed: number;
    processing: number;
    total: number;
    last_updated: Date | null;
  };
}

export const SyncInfoStatus = ({ displayName, documentStatusInfo }: Props) => {
  const { columns, rows } = useMemo(() => {
    const processedRatio =
      documentStatusInfo.processed / documentStatusInfo.total;
    const columns: GridColDef[] = [
      {
        field: "source",
        headerName: "Source",
        width: 200,
      },
      {
        field: "status",
        headerName: "Processing Status",
        flex: 1,
        minWidth: 200,
        renderCell: () => {
          const { processing, processed, total } = documentStatusInfo;
          if (processed === total || !total) {
            return "Up to Date";
          }

          return (
            <div className="flex items-center gap-3">
              <div className="shrink-0">
                <Spinner />
              </div>
              {processing > 0 ? (
                <div>
                  Processing {processing} document{processing === 1 ? "" : "s"}
                  ...
                </div>
              ) : (
                <div>Processing</div>
              )}
            </div>
          );
        },
      },
      {
        field: "processed",
        headerName: "Processed",
        width: 120,
      },
      {
        field: "totalDocuments",
        headerName: "Total",
        width: 120,
      },
      {
        field: "lastUpdated",
        headerName: "Last Updated",
        width: 150,
      },
    ];

    const rows = [
      {
        id: 1,
        source: displayName,
        status: processedRatio >= 1 ? "Up to Date" : "Processing",
        processed: documentStatusInfo.processed,
        totalDocuments: documentStatusInfo.total,
        lastUpdated: documentStatusInfo.last_updated
          ? dayjs(documentStatusInfo.last_updated).format("MM/DD/YYYY")
          : "-",
      },
    ];
    return { columns, rows };
  }, [documentStatusInfo]);

  return (
    <DataGrid
      rows={rows || []}
      columns={columns || []}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableColumnSorting
      hideFooter
    />
  );
};
