import useSWRMutation from "swr/mutation";
import { api } from "./helpers/APIClient";
import { CreateProjectBodyType } from "@trunk-tools/txt-shared";

export const useCreateProject = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const createProjectFetch = async (
    key: unknown,
    { arg }: { arg: CreateProjectBodyType },
  ) => {
    const result = await api.createProject({
      ...arg,
    });
    if (!result) {
      throw new Error("Failed to create project");
    }
    return result;
  };

  return useSWRMutation(["createProject"], createProjectFetch, {
    onSuccess,
    onError,
  });
};
