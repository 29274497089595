import { createContext, useContext, ReactNode } from "react";
import { LocalCorpusFilterFilter } from "../../CorpusFilters.types";

interface DataAndDocumentsContextType {
  searchTerm: string;
  debouncedSearchTerm: string;
  filters: LocalCorpusFilterFilter[];
  searchedAt: Date;
}

const DataAndDocumentsContext = createContext<
  DataAndDocumentsContextType | undefined
>(undefined);

export const useDataAndDocumentsContext = () => {
  const context = useContext(DataAndDocumentsContext);
  if (context === undefined) {
    throw new Error(
      "useDataAndDocumentsContext must be used within a DataAndDocumentsProvider",
    );
  }
  return context;
};

interface DataAndDocumentsProviderProps {
  children: ReactNode;
  searchTerm: string;
  debouncedSearchTerm: string;
  filters: LocalCorpusFilterFilter[];
  searchedAt: Date;
}

export const DataAndDocumentsProvider = ({
  children,
  searchTerm,
  debouncedSearchTerm,
  filters,
  searchedAt,
}: DataAndDocumentsProviderProps) => {
  return (
    <DataAndDocumentsContext.Provider
      value={{
        searchTerm,
        debouncedSearchTerm,
        filters,
        searchedAt,
      }}
    >
      {children}
    </DataAndDocumentsContext.Provider>
  );
};
