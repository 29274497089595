import { SVGProps } from "react";

interface TrunkMessageAvatarProps extends SVGProps<SVGSVGElement> {
  fill?: string;
}

export const TrunkMessageAvatar = ({
  fill = "#1D1D1A",
  ...props
}: TrunkMessageAvatarProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8937 23.4167C11.5627 23.4167 11.2432 23.392 10.9238 23.3692C10.9506 24.4075 11.0591 25.3566 11.2724 26.2061C12.4824 26.4936 13.9222 26.4291 15.2371 26.0798C17.2434 25.5483 19.2205 24.3487 20.1111 22.4505V20.9746C18.9139 21.7794 17.5325 22.4059 16.0683 22.8206C14.6449 23.224 13.2297 23.4167 11.8937 23.4167Z"
      fill={fill}
    />
    <path
      d="M0 0V6.80413H10.9068V20.4864C12.1833 20.6164 13.6149 20.4674 14.9054 20.1011C17.0807 19.4842 19.2373 18.1706 20.1116 16.1585V12.0346C20.0836 11.9559 20.0662 11.8809 20.0347 11.8012L20.1116 11.7812V6.80603H32V0H0Z"
      fill={fill}
    />
    <path
      d="M22.6836 32.0009C23.8295 31.9895 25.0746 31.9278 26.4303 31.8149V27.3066C25.8102 29.2058 24.4264 30.8269 22.6836 32.0009Z"
      fill={fill}
    />
    <path
      d="M16.1347 31.2257C17.3727 31.2143 18.7063 30.8261 19.8301 30.2083C21.7431 29.1567 23.3542 27.3439 23.3111 25.0413C22.9928 25.0214 22.6921 24.9929 22.4181 24.9512C20.9632 26.8219 18.718 28.1364 16.313 28.7742C15.1275 29.0883 13.9582 29.2392 12.8496 29.2392C12.8472 29.2392 12.8461 29.2392 12.8438 29.2392C13.6423 30.1058 14.7195 30.7682 16.1347 31.2276V31.2257Z"
      fill={fill}
    />
  </svg>
);
