import { api } from "./helpers/APIClient";
import { useState } from "react";

export const useCreateArtifactVersion = (artifactId: string) => {
  console.log("artifactId", artifactId);
  const [isCreating, setIsCreating] = useState(false);

  const createVersion = async (content: string) => {
    setIsCreating(true);
    try {
      const response = await api.createArtifactVersion({
        artifact_id: artifactId,
        content,
      });
      return response;
    } catch (error) {
      console.error("Failed to create artifact version:", error);
      throw error;
    } finally {
      setIsCreating(false);
    }
  };

  return {
    createVersion,
    isCreating,
  };
};
