import { DataGrid, GridColDef, getGridStringOperators } from "@mui/x-data-grid";
import {
  formatPhoneNumberDisplay,
  GetUsersResponseType,
} from "@trunk-tools/txt-shared";
import { useBusinessUsers } from "@/dataHooks/user.dataHook";
import { Button, Chip, Tooltip } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import { useState, useMemo } from "react";
import { InviteUserModal } from "./InviteUserModal.component";
import { Profile } from "../Profile/Profile";
import { useDataGridQuery } from "@/hooks/useDataGridQuery";

const filterOperators = getGridStringOperators().filter(
  (operator) => operator.value === "contains",
);

// https://mui.com/x/react-data-grid/pagination/#server-side-pagination
export const ManageBusinessUsersTab = () => {
  const {
    setGridPaginationModel,
    setGridSortModel,
    setGridFilterModel,
    queryOptions,
  } = useDataGridQuery();

  const { gridPaginationModel, gridSortModel, gridFilterModel } = queryOptions;

  const { data, isLoading, mutate } = useBusinessUsers(queryOptions);
  const users = data?.users || [];
  const count = data?.count || 0;

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showEditUserDetailsModal, setShowEditUserDetailsModal] =
    useState(false);
  const [targetUser, setTargetUser] = useState<
    | {
        first_name: string;
        last_name: string;
        job_title: string | null;
        email: string;
        phone: string | null;
        id: string;
      }
    | undefined
  >();

  const userCols: GridColDef<NonNullable<typeof users>[0]>[] = useMemo(
    () => [
      {
        field: "first_name",
        headerName: "NAME",
        renderCell: ({ row }) => `${row.first_name} ${row.last_name}`,
        width: 150,
        filterOperators,
        hideable: false,
      },
      {
        field: "job_title",
        headerName: "JOB TITLE",
        filterOperators,
      },
      {
        filterable: false,
        sortable: false,
        field: "projects",
        headerName: "ACTIVE PROJECTS",
        renderCell: ({
          row: { project_users },
        }: {
          row: GetUsersResponseType["users"][number];
        }) =>
          project_users
            .reduce<string[]>(
              (acc, { project: { name, project_question_status } }) => {
                if (project_question_status === "READY_FOR_QUESTIONS") {
                  acc.push(name);
                }
                return acc;
              },
              [],
            )
            .join(", "),
        width: 150,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 300,
        filterOperators,
      },
      {
        field: "phone",
        headerName: "PHONE",
        valueFormatter: (phone?: string) =>
          phone ? formatPhoneNumberDisplay({ phoneNumber: phone }) : "",
        width: 150,
        filterOperators,
      },
      {
        sortable: false,
        field: "action",
        headerName: "ACTIONS",
        width: 500,
        renderCell: ({ row }) =>
          row.idp_managed ? (
            <Tooltip title="This user profile is managed by their IDP and can not be edited">
              <Chip
                label="IDP Managed"
                variant="outlined"
                className="cursor-not-allowed"
              />
            </Tooltip>
          ) : (
            <>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                startIcon={<Edit />}
                onClick={() => {
                  setTargetUser(row);
                  setShowEditUserDetailsModal(true);
                }}
              >
                EDIT USER DETAILS
              </Button>
            </>
          ),
      },
    ],
    [users],
  );

  return (
    <>
      <Button
        variant="contained"
        color="success"
        className="mb-4"
        onClick={() => setShowInviteUserModal(true)}
        startIcon={<Add />}
      >
        Invite New User
      </Button>
      {showInviteUserModal && (
        <InviteUserModal onClose={() => setShowInviteUserModal(false)} />
      )}
      {showEditUserDetailsModal && targetUser && (
        <Profile
          onSuccess={mutate}
          user={targetUser}
          onClose={() => setShowEditUserDetailsModal(false)}
        />
      )}
      <DataGrid
        rowCount={count}
        disableRowSelectionOnClick
        paginationModel={gridPaginationModel}
        sortModel={gridSortModel}
        filterModel={gridFilterModel}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPaginationModelChange={setGridPaginationModel}
        onSortModelChange={setGridSortModel}
        onFilterModelChange={setGridFilterModel}
        loading={isLoading}
        rows={users}
        columns={userCols}
      />
    </>
  );
};
