// Components
import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { DateFilterSelector } from "./DateFilterSelector.component";
import { Suspense } from "react";

// Constants
import { CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS } from "../../CorpusFilterBlock.constants";
import { INTEGRATION_MODULE_LABEL_MAP } from "../../CorpusFilters.constants";
import { colors } from "@trunk-tools/ui";

// Contexts
import { useContext } from "react";
import { CorpusFiltersContext } from "../../CorpusFilters.context";

// Hooks
import { useDocument } from "@/dataHooks/documents.dataHook";
import { useFolder } from "@/dataHooks/folders.dataHook";

// Types
import { LocalCorpusFilterFilter } from "../../CorpusFilters.types";

const DocumentDetail = ({ documentId }: { documentId: string }) => {
  const { document } = useDocument({ documentId });

  return (
    <>
      <div className="flex flex-row gap-2.5 items-center group">
        <DescriptionOutlinedIcon />
        <Typography variant="body2" noWrap className="grow">
          {document.name}
        </Typography>
        <Tooltip arrow placement="top" title="View Document in New Window">
          <Link
            href={`/projects/${document.project_id}/documents/${document.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-1 items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          >
            <OpenInNewOutlinedIcon fontSize="small" />
          </Link>
        </Tooltip>
      </div>
    </>
  );
};

const DocumentDetailWithSuspense = ({ documentId }: { documentId: string }) => {
  return (
    <Suspense fallback={<Typography variant="body2">Loading...</Typography>}>
      <DocumentDetail documentId={documentId} />
    </Suspense>
  );
};

const FolderName = ({ folderId }: { folderId: string }) => {
  const { data: folder } = useFolder({ folderId });

  return <Typography variant="body2">{folder?.name}</Typography>;
};

export const SingleFilterRefinement = ({
  filter,
}: {
  filter: LocalCorpusFilterFilter;
}) => {
  const { onFilterBlockChange, onFilterBlockDelete } =
    useContext(CorpusFiltersContext);

  let label = "";
  let isDocument = false;
  let isFolder = false;
  let canSetModifiedDates = false;
  if (filter.document_types?.length) {
    const documentType = filter.document_types![0];
    const foundDocumentType = CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS.find(
      (root) => root.id === documentType,
    );
    label = foundDocumentType?.label ?? documentType;
    canSetModifiedDates = true;
  }

  if (filter.folder_ids?.length) {
    isFolder = true;
    label = `${filter.folder_ids[0]}`;
    canSetModifiedDates = true;
  }

  if (filter.document_ids?.length) {
    isDocument = true;
    label = `${filter.document_ids[0]}`;
  }

  if (filter.document_source_module) {
    canSetModifiedDates = true;
    console.log("filter.document_source_module", filter.document_source_module);
    label = INTEGRATION_MODULE_LABEL_MAP[filter.document_source_module]
      ? INTEGRATION_MODULE_LABEL_MAP[filter.document_source_module]
      : filter.document_source_module;
  }

  if (!label && filter.document_source_system) {
    canSetModifiedDates = true;
    label = "All Documents";
  }

  if (!label) {
    return null;
  }

  /**
   * This is to accommodate for more refinements options in the future
   */
  const additionalRefinementsAvailable = canSetModifiedDates;

  return (
    <div
      key={filter._uuidKey}
      className="relative border border-black rounded-sm p-2.5"
      style={{ backgroundColor: colors["tt-modal-bg"] }}
    >
      <div className="pr-6">
        {isDocument && (
          <DocumentDetailWithSuspense documentId={filter.document_ids![0]} />
        )}
        {isFolder && (
          <div className="flex flex-row gap-2 items-center">
            <FolderOutlinedIcon />
            <FolderName folderId={filter.folder_ids![0]} />
          </div>
        )}
        {!isDocument && !isFolder && (
          <div className="flex flex-row gap-2 items-center">
            <FolderOutlinedIcon />
            <Typography variant="body2">{label}</Typography>
          </div>
        )}
      </div>
      {additionalRefinementsAvailable && (
        <div className="mt-2 flex flex-col items-start gap-2">
          {canSetModifiedDates && (
            <DateFilterSelector
              initialStartDate={filter.start_date}
              initialEndDate={filter.end_date}
              onChange={({ startDate, endDate }) => {
                onFilterBlockChange({
                  uuidKey: filter._uuidKey,
                  operation: "update",
                  commonAttributes: {
                    start_date: startDate?.toISOString(),
                    end_date: endDate?.toISOString(),
                  },
                });
              }}
            />
          )}
        </div>
      )}
      <IconButton
        color="secondary"
        size="small"
        className="absolute top-1 right-1"
        onClick={() => {
          onFilterBlockDelete({
            uuidKey: filter._uuidKey,
          });
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};
