import { useContext, useEffect, useState } from "react";

// Components
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  Button,
  Link,
} from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

// Contexts
import { CorpusFiltersContext } from "../CorpusFilters.context";

// Types
import { LocalCorpusFilterFilter } from "../CorpusFilters.types";

// Hooks
import { useCurrentProject, useSearchProjectDocuments } from "dataHooks";

// Constants
import {
  DocumentTypes,
  SearchProjectDocumentsResponseType,
  SyncConfigModules,
} from "@trunk-tools/txt-shared";

const PAGE_SIZE = 50;

export const DocumentsList = ({
  filters,
  searchTerm,
  type,
  uacProjectId,
  syncConfigModule,
  uacFolderId,
  showNoDocumentsMessage,
  documentCount,
  pageSize = PAGE_SIZE,
}: {
  filters: LocalCorpusFilterFilter[];
  searchTerm?: string;
  type?: DocumentTypes;
  uacProjectId?: string;
  syncConfigModule?: SyncConfigModules;
  uacFolderId?: string;
  showNoDocumentsMessage: boolean;
  documentCount: number;
  pageSize?: number;
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [skip, setSkip] = useState(0);
  const [combinedDocuments, setCombinedDocuments] = useState<
    SearchProjectDocumentsResponseType["documents"]
  >([]);

  const { onFilterBlockChange } = useContext(CorpusFiltersContext);

  const { currentProject } = useCurrentProject();

  const { data, isLoading } = useSearchProjectDocuments({
    searchTerm,
    skip,
    take: pageSize,
    type,
    uacProjectId,
    syncConfigModule,
    uacFolderId,
  });

  useEffect(() => {
    const newSelectedItems: string[] = [];
    filters.forEach((filter) => {
      if (filter.document_ids?.length) {
        newSelectedItems.push(...filter.document_ids);
      }
    });

    setSelectedItems(newSelectedItems);
  }, [filters]);

  const { documents = [] } = data || {};

  useEffect(() => {
    setCombinedDocuments([]);
  }, [searchTerm]);

  useEffect(() => {
    setCombinedDocuments((prevSearchResults) => [
      ...prevSearchResults,
      ...(data?.documents || []),
    ]);
  }, [data]);

  return (
    <>
      {!isLoading && documents.length === 0 && showNoDocumentsMessage && (
        <Typography variant="body1" className="text-gray-500">
          {searchTerm ? `No Results for "${searchTerm}"` : "No Documents Found"}
        </Typography>
      )}
      <FormGroup className="flex flex-col gap-3">
        {combinedDocuments.map((document) => {
          const documentId = document.id;
          const isSelected = selectedItems.includes(documentId);
          return (
            <FormControlLabel
              key={documentId}
              control={<Checkbox checked={isSelected} />}
              className="group"
              label={
                <div className="flex flex-row items-center gap-2">
                  <DescriptionOutlinedIcon />
                  <div className="flex flex-col gap-0">
                    <Typography variant="body1" noWrap>
                      {document.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="text-gray-500 flex flex-row items-center"
                      noWrap
                    >
                      {document.type} |{" "}
                      {document.uac_project?.name || "Unknown Source"}
                      <Link
                        href={`/projects/${currentProject.id}/documents/${document.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex flex-row items-center gap-1 opacity-100 desktop:opacity-0 group-hover:opacity-100 transition-opacity ml-3"
                      >
                        <OpenInNewOutlinedIcon fontSize="small" />
                        <span>View</span>
                      </Link>
                    </Typography>
                  </div>
                </div>
              }
              onChange={(_event, checked) => {
                if (checked) {
                  onFilterBlockChange({
                    operation: "add",
                    primaryAttribute: "document_ids",
                    primaryAttributeValue: documentId,
                    /**
                     * Keep track of the source system if it exists, to make
                     * it easier to categorize the filter in the refinements
                     * panel.
                     */
                    ...(document.uac_project
                      ? {
                          commonAttributes: {
                            document_source_system: document.uac_project.id,
                          },
                        }
                      : {}),
                  });
                } else {
                  onFilterBlockChange({
                    operation: "remove",
                    primaryAttribute: "document_ids",
                    primaryAttributeValue: documentId,
                  });
                }
              }}
            />
          );
        })}
        {combinedDocuments.length < documentCount &&
          combinedDocuments.length > 0 && (
            <div>
              <Button
                onClick={() => setSkip(skip + PAGE_SIZE)}
                color="info"
                size="small"
                disabled={isLoading}
              >
                Load{" "}
                {Math.min(documentCount - combinedDocuments.length, pageSize)}{" "}
                more
              </Button>
            </div>
          )}
      </FormGroup>
    </>
  );
};
