import { UACProjectAccount } from "@trunk-tools/txt-shared/src/schemas/uac.schema";

export const getUACProjectIntegrations = ({
  uacProjects,
}: {
  uacProjects: UACProjectAccount[];
}) =>
  uacProjects
    .reduce<{ system: string; count: number }[]>(
      (integrations, { uac_account: { source_system } }) => {
        const integration = integrations.find(
          ({ system }) => system === source_system,
        );

        if (integration) {
          integration.count++;
        } else {
          integrations.push({ system: source_system, count: 1 });
        }

        return integrations;
      },
      [],
    )
    .map(({ system, count }) => `${system} ${count > 1 ? `(${count})` : ""}`)
    .join(", ");
