import { DocumentTypes, ExternalDocumentType } from "@trunk-tools/txt-shared";

export const CORPUS_FILTER_BLOCK_CLASSNAMES_ROOT =
  "bg-[#161f26] border border-[#0D1217] p-5 rounded";

type DocumentTypeTreeRoot = {
  id: ExternalDocumentType;
  internalId: keyof typeof DocumentTypes;
  label: string;
};

export const CLASSIFIED_DOCUMENT_TYPE_TREE_ROOTS: DocumentTypeTreeRoot[] = [
  {
    id: ExternalDocumentType.rfi,
    internalId: DocumentTypes.RFI,
    label: "RFIs",
  },
  {
    id: ExternalDocumentType.submittal,
    internalId: DocumentTypes.Submittal,
    label: "Submittals",
  },
  {
    id: ExternalDocumentType.spec,
    internalId: DocumentTypes.Specification,
    label: "Specifications",
  },
  {
    id: ExternalDocumentType.drawing,
    internalId: DocumentTypes.Drawing,
    label: "Drawings",
  },
  {
    id: ExternalDocumentType.form,
    internalId: DocumentTypes.Form,
    label: "Forms",
  },
  {
    id: ExternalDocumentType.issue,
    internalId: DocumentTypes.Issue,
    label: "Issues",
  },
  {
    id: ExternalDocumentType.meeting_notes,
    internalId: DocumentTypes.MeetingNotes,
    label: "Meetings",
  },
];
