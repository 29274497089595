import useSWR from "swr";
import { api } from "./helpers/APIClient";
import {
  GridSortModel,
  GridFilterModel,
  GridPaginationModel,
} from "@mui/x-data-grid";

export const useBusinessUsers = ({
  gridSortModel,
  gridFilterModel,
  gridPaginationModel,
}: {
  gridSortModel?: GridSortModel;
  gridFilterModel?: GridFilterModel;
  gridPaginationModel?: GridPaginationModel;
}) =>
  useSWR(
    ["businessUsers", gridSortModel, gridFilterModel, gridPaginationModel],
    async (): Promise<Awaited<ReturnType<typeof api.businessUsers>>> => {
      const data = await api.businessUsers({
        gridSortModel,
        gridFilterModel,
        gridPaginationModel,
      });
      return data;
    },
  );
