import useSWR from "swr";
import { api } from "./helpers/APIClient";

export const useFolder = ({ folderId }: { folderId: string }) => {
  const fetch = async () => {
    const data = await api.getFolder({
      folder_id: folderId,
    });
    return data;
  };

  return useSWR(["folder", folderId], fetch, {
    suspense: true,
  });
};
