import { useNavigate } from "react-router-dom";
import { ExpandableMenu } from "@trunk-tools/ui";
import { useCurrentProject, useMe, useUserProjects } from "dataHooks";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useUserConversations } from "@/dataHooks/conversations.dataHook";
import { useCanDo } from "@/hooks/useCanDo";
import { ProjectPermission } from "@trunk-tools/txt-shared";
import { useFeatureFlag } from "@/dataHooks/featureFlag.dataHook";

type ExpandableMenuItem = Parameters<
  typeof ExpandableMenu
>["0"]["items"][number];

export const ProjectPicker = () => {
  const navigate = useNavigate();
  const idNavigate = useIdNavigate();

  const { currentProject } = useCurrentProject();
  const { changeProject } = useMe();
  const { refreshUserConversations } = useUserConversations();
  const { projects: userProjects } = useUserProjects({
    sortByName: "asc",
  });
  const { flagEnabled: selfServeFlagEnabled } = useFeatureFlag(
    (ff) => ff.SelfServeProjectManagement,
  );

  const { can: canManageDocs } = useCanDo({
    permission: ProjectPermission.ManageDocuments,
  });

  const projects: ExpandableMenuItem[] = userProjects
    .filter((p) => p.id !== currentProject.id)
    .map((p) => ({
      type: "item",
      label: p.name,
      onClick: async () => {
        changeProject(p.id);
        navigate(`/projects/${p.id}/conversations/new`);
        refreshUserConversations();
      },
    }));

  if (projects.length > 0 && canManageDocs) {
    projects.unshift({ type: "separator" });
  }

  return (
    <ExpandableMenu
      bottomStickyItem={
        selfServeFlagEnabled
          ? {
              type: "item",
              label: "manage & create projects",
              onClick: () => {
                navigate("/manage");
              },
            }
          : undefined
      }
      title={currentProject.name}
      items={
        canManageDocs
          ? [
              {
                type: "button",
                label: "manage integrations",
                onClick: () => idNavigate("/integrations"),
              },
              ...projects,
            ]
          : [...projects]
      }
    />
  );
};
