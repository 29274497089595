import { MarkdownFormatter } from "@trunk-tools/ui";
import { useState, useRef, useEffect } from "react";
import { Crepe } from "@milkdown/crepe";
import { Milkdown, MilkdownProvider, useEditor } from "@milkdown/react";
import { useCreateArtifactVersion } from "../../dataHooks/artifact.dataHook";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CheckIcon from "@mui/icons-material/Check";
import "@milkdown/crepe/theme/common/style.css";
import "../../styles/crepe-theme.css";
import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { downloadWordDocument } from "../../utils/markdownToWord";

type EditorType = InstanceType<typeof Crepe>;

interface ActionButtonProps {
  onClick: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  disabled = false,
  icon,
  children,
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    variant="contained"
    startIcon={icon}
    sx={{
      backgroundColor: disabled ? "rgba(41,49,67,0.48)" : "#ededea",
      color: disabled ? "#171717" : "#0A545E",
      border: disabled ? "2px solid #12151D" : "2px solid #DADADA",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
      "&:active": {
        backgroundColor: "#d4d4d1",
      },
      padding: "6px 12px",
      gap: "8px",
    }}
  >
    {children}
  </Button>
);

interface ArtifactViewerProps {
  onClose: () => void;
  title?: string;
  onSave?: () => void;
  artifactId: string;
  versions: Array<{
    content: string;
    version: number;
    created_at: Date;
  }>;
}

interface CrepeEditorProps {
  content: string;
  editorRef: React.MutableRefObject<EditorType | null>;
}

const CrepeEditor: React.FC<CrepeEditorProps> = ({ content, editorRef }) => {
  useEditor((root) => {
    const crepe = new Crepe({
      root,
      defaultValue: content,
    });
    editorRef.current = crepe;
    return crepe;
  }, []);

  return <Milkdown />;
};

export const MilkdownEditorWrapper: React.FC<{
  content: string;
  editorRef: React.MutableRefObject<EditorType | null>;
}> = ({ content, editorRef }) => {
  return (
    <MilkdownProvider>
      <CrepeEditor content={content} editorRef={editorRef} />
    </MilkdownProvider>
  );
};

const EditModeHeader: React.FC<{
  title: string;
  onSave: () => void;
  isCreating: boolean;
  onExitEdit: () => void;
  editorRef: React.MutableRefObject<EditorType | null>;
  originalContent: string;
}> = ({
  title,
  onSave,
  isCreating,
  onExitEdit,
  editorRef,
  originalContent,
}) => {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentContent = editorRef.current?.getMarkdown() || "";
      setHasChanges(currentContent !== originalContent);
    }, 500);

    return () => clearInterval(interval);
  }, [originalContent]);

  return (
    <div className="flex items-center justify-between p-4 border-b border-gray-700 sticky top-0 z-10 bg-blue-500 rounded-t-md">
      <div className="flex items-center gap-x-4 text-white text-lg font-medium">
        {title}
      </div>
      <div className="flex items-center gap-x-4">
        <ActionButton
          onClick={onSave}
          disabled={isCreating || !hasChanges}
          icon={<CheckIcon className="w-5 h-5 " />}
        >
          {isCreating ? "SAVING..." : "SAVE CHANGES"}
        </ActionButton>
        <button
          onClick={onExitEdit}
          className="text-gray-400 hover:text-gray-200 transition-colors duration-200"
          aria-label="Exit edit mode"
        >
          <span className="text-xl">✕</span>
        </button>
      </div>
    </div>
  );
};

const EditModeFooter: React.FC<{
  currentVersion: number;
  totalVersions: number;
}> = ({ currentVersion, totalVersions }) => (
  <div className="border-t border-gray-700 p-4 flex items-center justify-between bg-blue-500 rounded-b-md">
    <div className="flex items-center gap-x-4 text-gray-400">
      <span className="text-sm">
        VERSION {currentVersion} / {totalVersions}
      </span>
    </div>
    <div className="flex items-center gap-x-2">
      {/* Additional toolbar buttons can be added here */}
    </div>
  </div>
);

const ViewModeHeader: React.FC<{
  title: string;
  onStartEdit: () => void;
  onClose: () => void;
}> = ({ title, onStartEdit, onClose }) => (
  <div className="flex items-center justify-between p-4 border-b border-gray-700 sticky top-0 z-10 bg-[#0D1117] rounded-t-md">
    <div className="flex items-center gap-x-4 text-white text-lg font-medium">
      {title}
    </div>
    <div className="flex items-center gap-x-4">
      <button
        onClick={onStartEdit}
        className="text-brand hover:text-brand-light transition-colors duration-200 text-sm px-3 py-1.5 rounded flex items-center gap-x-2"
      >
        <EditNoteIcon className="w-5 h-5" />
        EDIT
      </button>
      <button
        onClick={onClose}
        className="text-gray-400 hover:text-gray-200 transition-colors duration-200"
        aria-label="Close artifact viewer"
      >
        <span className="text-xl">✕</span>
      </button>
    </div>
  </div>
);

const ViewModeFooter: React.FC<{
  onBack: () => void;
  onForward: () => void;
  canGoBack: boolean;
  canGoForward: boolean;
  currentVersion: number;
  totalVersions: number;
  onDownload: () => void;
}> = ({
  onBack,
  onForward,
  canGoBack,
  canGoForward,
  currentVersion,
  totalVersions,
  onDownload,
}) => (
  <div className="border-t border-gray-700 p-4 flex items-center justify-between bg-[#0D1117] rounded-b-md">
    <div className="flex items-center gap-x-4 text-gray-400">
      <button
        onClick={onBack}
        disabled={!canGoBack}
        className={`text-base ${
          canGoBack ? "hover:text-gray-200" : "opacity-50 cursor-not-allowed"
        }`}
        aria-label="Older version"
      >
        ◀
      </button>
      <span className="text-sm">
        VERSION {currentVersion} / {totalVersions}
      </span>
      <button
        onClick={onForward}
        disabled={!canGoForward}
        className={`text-base ${
          canGoForward ? "hover:text-gray-200" : "opacity-50 cursor-not-allowed"
        }`}
        aria-label="Newer version"
      >
        ▶
      </button>
    </div>
    <div className="flex items-center gap-x-2">
      <Button
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={onDownload}
        sx={{
          backgroundColor: "#EDEDEA",
          color: "#0A545E",
          border: "2px solid #DADADA",
          "&:hover": {
            backgroundColor: "white",
          },
          "&:active": {
            backgroundColor: "#D4D4D1",
          },
          textTransform: "none",
          px: 1.5,
          py: 0.75,
        }}
      >
        DOWNLOAD FILE
      </Button>
    </div>
  </div>
);

export const ArtifactViewer = ({
  onClose,
  title,
  onSave,
  artifactId,
  versions,
}: ArtifactViewerProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const editorRef = useRef<EditorType | null>(null);
  const { createVersion, isCreating } = useCreateArtifactVersion(artifactId);

  const handleSave = async () => {
    if (editorRef.current) {
      const markdown = editorRef.current.getMarkdown();
      await createVersion(markdown);
      setIsEditing(false);
      onSave?.();
    }
  };

  const handleDownload = () => {
    const content = versions[currentVersionIndex]?.content;
    let documentTitle = title || "Generated Content";
    if (versions.length > 1) {
      documentTitle = `${documentTitle} - Version ${
        versions.length - currentVersionIndex
      }`;
    }
    if (content) {
      downloadWordDocument(content, `${documentTitle}.docx`);
    }
  };

  const canGoBack = currentVersionIndex < versions.length - 1;
  const canGoForward = currentVersionIndex > 0;

  const handleBack = () => {
    if (canGoBack) {
      setCurrentVersionIndex(currentVersionIndex + 1);
    }
  };

  const handleForward = () => {
    if (canGoForward) {
      setCurrentVersionIndex(currentVersionIndex - 1);
    }
  };

  return (
    <div
      className="bg-[#0D1117] rounded-lg flex flex-col h-full"
      key={artifactId}
    >
      {isEditing ? (
        <EditModeHeader
          title={title || "Generated Content"}
          onSave={handleSave}
          isCreating={isCreating}
          onExitEdit={() => setIsEditing(false)}
          editorRef={editorRef}
          originalContent={versions[currentVersionIndex]?.content}
        />
      ) : (
        <ViewModeHeader
          title={title || "Generated Content"}
          onStartEdit={() => setIsEditing(true)}
          onClose={onClose}
        />
      )}
      <div className="flex-1 overflow-y-auto p-6 text-white bg-[#0f0f0f] min-h-0">
        {isEditing ? (
          <div className="h-full">
            <MilkdownEditorWrapper
              content={versions[currentVersionIndex]?.content}
              editorRef={editorRef}
            />
          </div>
        ) : (
          <MarkdownFormatter text={versions[currentVersionIndex]?.content} />
        )}
      </div>
      {isEditing ? (
        <EditModeFooter
          currentVersion={versions.length - currentVersionIndex}
          totalVersions={versions.length}
        />
      ) : (
        <ViewModeFooter
          onBack={handleBack}
          onForward={handleForward}
          canGoBack={canGoBack}
          canGoForward={canGoForward}
          currentVersion={versions.length - currentVersionIndex}
          totalVersions={versions.length}
          onDownload={handleDownload}
        />
      )}
    </div>
  );
};
