import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import tWhite from "public/trunk_tools_white.svg";
import { Link, useNavigate } from "react-router-dom";

type BreadcrumbLink = {
  type: "link";
  label: string;
  redirect: string | (() => void);
};

type BreadcrumbText = {
  type: "text";
  label: string;
};

export type BreadcrumbPropsType = BreadcrumbLink | BreadcrumbText;

type BreadcrumbNavbarProps = {
  title: string;
  breadcrumbs: BreadcrumbPropsType[];
};

const HomeBreadcrumb: BreadcrumbLink = {
  type: "link",
  label: "Home",
  redirect: "/",
};

const Breadcrumb = (props: BreadcrumbPropsType) => {
  const { type, label } = props;

  if (type === "text") {
    return <div>{label}</div>;
  }

  const { redirect } = props;

  return typeof redirect === "string" ? (
    <Link className="underline" to={{ pathname: redirect }}>
      {label}
    </Link>
  ) : (
    <div
      className="cursor-pointer underline"
      onClick={() => {
        redirect();
      }}
    >
      {label}
    </div>
  );
};

export const BreadcrumbNavbar = ({
  title,
  breadcrumbs,
}: BreadcrumbNavbarProps) => {
  const navigate = useNavigate();
  const breadcrumbList = [HomeBreadcrumb, ...breadcrumbs];

  return (
    <div className="w-full">
      <div className="w-full flex flex-row items-center gap-x-6 py-8">
        <img
          src={tWhite}
          onClick={() => navigate("/")}
          alt="trunktext logo"
          className="cursor-pointer w-13 h-8"
        />
        <p className="uppercase">{title}</p>
      </div>
      <div className="mt-2 mb-8">
        <Breadcrumbs separator={<NavigateNext />}>
          {breadcrumbList.map((b) => (
            <Breadcrumb key={`breadcrumb-${b.label}`} {...b} />
          ))}
        </Breadcrumbs>
      </div>
    </div>
  );
};
