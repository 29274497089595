export const SYNC_CONFIG_MODULE_LABEL_MAP = {
  rfis: "RFIs",
  submittals: "Submittals",
  specs: "Specs",
  drawings: "Drawings",
  meetings: "Meetings",
  folders: "Folders",
  forms: "Forms",
  issues: "Issues",
  specification_sections: "Specification Sections",
  daily_logs: "Daily Logs",
};

export const INTEGRATION_MODULE_LABEL_MAP = {
  ChangeOrder: "Change Orders",
  Contract: "Contracts",
  Drawing: "Drawings",
  File: "Folders",
  Form: "Forms",
  Inspection: "Inspections",
  Issue: "Issues",
  MeetingNotes: "Meeting Notes",
  Permit: "Permits",
  RFI: "RFIs",
  Schedule: "Schedules",
  Specification: "Specifications",
  Submittal: "Submittals",
  DailyLog: "Daily Logs",
};

export const NON_ARRAY_CORPUS_FILTER_ATTRIBUTES = [
  "document_source_system",
  "document_source_module",
];

export const DEFAULT_CORPUS_FILTER = {
  id: "default",
  name: "All Data & Documents",
  filters: [],
};
