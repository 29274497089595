import { useMemo, useState } from "react";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { useDebounceValue } from "usehooks-ts";

export const useDataGridQuery = ({
  page = 0,
  pageSize = 50,
  debounceMs = 250,
}: {
  page?: number;
  pageSize?: number;
  debounceMs?: number;
} = {}) => {
  const [gridPaginationModel, setGridPaginationModel] = useState({
    page,
    pageSize,
  });
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>([]);
  const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [debouncedGridFilterModel] = useDebounceValue(
    gridFilterModel,
    debounceMs,
  );

  const queryOptions = useMemo(
    () => ({
      gridPaginationModel,
      gridSortModel,
      gridFilterModel: debouncedGridFilterModel,
    }),
    [gridPaginationModel, gridSortModel, debouncedGridFilterModel],
  );

  return {
    setGridPaginationModel,
    setGridSortModel,
    setGridFilterModel,
    queryOptions,
  };
};
