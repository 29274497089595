import { useMemo, useState } from "react";
import { Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMe, useProjects } from "dataHooks";
import { Add, Edit } from "@mui/icons-material";
import { getUACProjectIntegrations } from "./utils/getUACProjectIntegrations";
import {
  getPermissibleProjects,
  ProjectPermission,
} from "@trunk-tools/txt-shared";
import { Link as RouterLink } from "react-router-dom";
import { CreateNewProjectModal } from "./CreateNewProjectModal.component";
import { CreateNewBusinessModal } from "./CreateNewBusinessModal.component";

export const ManageProjectsTab = () => {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showCreateBusinessModal, setShowCreateBusinessModal] = useState(false);
  const { data: projects, mutate } = useProjects();
  const { me } = useMe();

  const { hasGlobalPermission, hasProjectPermission, projectIds, businessIds } =
    useMemo(
      () =>
        getPermissibleProjects({
          user: me,
          permission: ProjectPermission.ManageProject,
        }),
      [me],
    );

  const cols = useMemo<GridColDef[]>(() => {
    const projCols: GridColDef[] = [
      { type: "string", field: "name", headerName: "PROJECT NAME", width: 200 },
      {
        type: "string",
        field: "uac_projects",
        headerName: "LIVE INTEGRATIONS",
        flex: 200,
        valueGetter: (uacProjects) =>
          getUACProjectIntegrations({
            uacProjects,
          }),
      },
    ];

    const actionCol: GridColDef = {
      type: "actions",
      field: "action",
      headerName: "ACTIONS",
      minWidth: 200,
      sortable: false,
      renderCell: ({ row: project }) => {
        const canManageProject =
          hasGlobalPermission ||
          projectIds.includes(project.id) ||
          businessIds.includes(project.business_id);

        return canManageProject ? (
          <Button
            component={RouterLink}
            to={`/manage/projects/${project.id}/users`}
            size="small"
            variant="contained"
            startIcon={<Edit />}
          >
            Manage Project
          </Button>
        ) : null;
      },
    };

    if (hasProjectPermission) {
      return [...projCols, actionCol];
    }

    return projCols;
  }, [hasProjectPermission, projectIds, businessIds]);

  return (
    <>
      {showCreateProjectModal && (
        <CreateNewProjectModal
          onSuccess={mutate}
          hasGlobalPermission={hasGlobalPermission}
          manageableBusinessIds={businessIds}
          onClose={() => setShowCreateProjectModal(false)}
        />
      )}
      {showCreateBusinessModal && (
        <CreateNewBusinessModal
          onClose={() => setShowCreateBusinessModal(false)}
        />
      )}
      {hasGlobalPermission && (
        <Button
          variant="contained"
          color="secondary"
          className="mb-4"
          onClick={() => setShowCreateBusinessModal(true)}
          startIcon={<Add />}
        >
          Create New Business
        </Button>
      )}
      {(hasGlobalPermission || businessIds.length > 0) && (
        <Button
          variant="contained"
          color="success"
          className="mb-4  ml-4"
          onClick={() => setShowCreateProjectModal(true)}
          startIcon={<Add />}
        >
          Create New Project
        </Button>
      )}

      <DataGrid rows={projects} columns={cols} hideFooter />
    </>
  );
};
